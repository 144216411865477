import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LanguageService } from './services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCookiesInfoComponent } from './components/dialog-cookies-info/dialog-cookies-info.component';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sabadell-aire-web';

  showCookieModal = false
  

  constructor(
    private languageService: LanguageService,
    public router: Router,
    private gtmService: GoogleTagManagerService,
    public infoDialog : MatDialog
    ){
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
            gtag('config', 'G-L0WQWPSFYH', {
              'page_path': event.urlAfterRedirects
            });            
         }
      })
    }


  ngOnInit(): void {
    let lang = this.languageService.getCurrentLanguage();
    this.languageService.setCurrentLanguage(lang);
    if(!localStorage.getItem("cookies_accepted")){
      //console.log("NO COOKIES");
      this.showCookieModal = true
    }
  }

  onActivate(event) {
    window.scrollTo(0, 0);
  }

  acceptCookies(){
    localStorage.setItem("cookies_accepted", "true");
    this.showCookieModal = false
  }
  openDialogInfoCookies(){
    const dialogRef = this.infoDialog.open(DialogCookiesInfoComponent);
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
