import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-about-web-side-bar',
  templateUrl: './about-web-side-bar.component.html',
  styleUrls: ['./about-web-side-bar.component.css']
})
export class AboutWebSideBarComponent implements OnInit {

  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  goToLink(url: string){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "",
        question: "Està a punt de sortir, vol continuar?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "yes") {
        window.open(url, "_blank");
      }
    })
  }

  selectedMenu(url:string):boolean{
     return this.router.url == url
  }


}
