import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AboutAirService } from 'src/app/services/about-air.service';


@Component({
  selector: 'app-about-air',
  templateUrl: './about-air.component.html',
  styleUrls: ['./about-air.component.css']
})
export class AboutAirComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }
}
