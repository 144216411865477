import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule} from '@angular/material/tree';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatChipsModule} from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MainComponent } from './pages/main/main.component';
import { MapComponent } from './components/map/map.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FooterComponent } from './components/footer/footer.component';
import { MapsComponent } from './pages/maps/maps.component';
import { DataAndStatisticsComponent } from './pages/data-and-statistics/data-and-statistics.component';
import { AboutAirComponent } from './pages/about-air/about-air.component';
import { AboutWebComponent } from './pages/about-web/about-web.component';
import { PollenSideBarComponent } from './components/pollen-side-bar/pollen-side-bar.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppConfigService } from './app-config';
import { DateAdapter, MatNativeDateModule} from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { AirSideBarComponent } from './components/air-side-bar/air-side-bar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import { HeaderComponent } from './components/header/header.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AccessibilityComponent } from './pages/accessibility/accessibility.component';
import { AboutWebSideBarComponent } from './components/about-web-side-bar/about-web-side-bar.component';
import { WebMapComponent } from './pages/web-map/web-map.component';
import { LegalWarnComponent } from './pages/legal-warn/legal-warn.component';
import { StationComponent } from './pages/station/station.component';
import { DialogCsvComponent } from './components/dialog-csv/dialog-csv.component';
import { AgmCoreModule } from '@agm/core';
import { ReportsComponent } from './pages/reports/reports.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DialogCookiesInfoComponent } from './components/dialog-cookies-info/dialog-cookies-info.component';
import { PollutionComponent } from './pages/pollution/pollution.component';
import { RegulationComponent } from './pages/regulation/regulation.component';
import { MeasuringEquimpentComponent } from './pages/measuring-equimpent/measuring-equimpent.component';
import { AirQualitiyLevelComponent } from './pages/air-qualitiy-level/air-qualitiy-level.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loadConfigService(configService: AppConfigService): Function {
  return () => { return configService.load() }; 
}



@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    MainComponent,
    MapComponent,
    FooterComponent,
    MapsComponent,
    DataAndStatisticsComponent,
    AboutAirComponent,
    AboutWebComponent,
    PollenSideBarComponent,
    AirSideBarComponent,
    HeaderComponent,
    DialogConfirmComponent,
    AccessibilityComponent,
    AboutWebSideBarComponent,
    WebMapComponent,
    LegalWarnComponent,
    StationComponent,
    DialogCsvComponent,
    ReportsComponent,
    ContactComponent,
    DialogCookiesInfoComponent,
    PollutionComponent,
    RegulationComponent,
    MeasuringEquimpentComponent,
    AirQualitiyLevelComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatCardModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatExpansionModule,
    NgxChartsModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    HttpClientModule,
    NgApexchartsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAfwRJDdn3j4Y8YPwi4XDA6RWlltqro0mo',
      language: localStorage && localStorage.language || 'ca'
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ca-CA' },
    { provide: APP_INITIALIZER, useFactory: loadConfigService , deps: [AppConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadConfigService , deps: [AppConfigService], multi: true },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: 'googleTagManagerId',  useValue: 'G-L0WQWPSFYH' },
    MatDatepickerModule
  ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
