<div id="footer-container">
    <div class="footer border-shadow mt-xs-2 mt-sm-2 mt-md-3 mt-5 mb-0" fxflex fxLayout="column" fxLayoutAlign="center center">
        <div fxflex fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
            <div></div>
            <div fxflex fxLayout="row" fxLayoutAlign="center center" class="genericTextSabadell" >
                <div><a [routerLink]="['/about_web/', {outlets: {'aboutWebRouter': ['accessibility']}}]">{{'ACCESSIBILITY.ACCESSIBILITY' | translate}}</a></div>
                <div style="margin-left: 20px;">|</div>
                <div style="margin-left: 20px;"><a [routerLink]="['/about_web/', {outlets: {'aboutWebRouter': ['web_map']}}]">{{'FOOTER.SITE_MAP' | translate}}</a></div>
                <div style="margin-left: 20px;">|</div>
                <div style="margin-left: 20px;"><a [routerLink]="['/about_web/',  {outlets: {'aboutWebRouter': ['legal_warn']}}]">{{'LEGAL_WARN.LEGAL_WARN' | translate}}</a></div>
            </div>
            <div fxLayout="column"  fxLayoutAlign="end center">
                <div style="margin-left: 20px;" > <button style="background-color: white;  border:none; font-size: large; font-weight: bold; color:gray;" (click)="goToTop()">^</button></div>
            </div>
        </div>
    </div>
    <div style="width: 100%; background-color: white;">
        <div  style="width: 100%;" fxflex fxLayout.xs="column" fxLayout.sm="column" fxLayout="row wrap"  fxLayoutAlign="space-around center" fxLayoutGap.xs="10px" fxLayoutGap.sm="20px">
            <div style="display: block; position: relative; padding: 10px;" ><img src="../../../assets/img/Edusi.png" alt="edusi" width="155" height="65"></div>
            <div style="display: block; position: relative; padding: 10px; bottom:5px"><img src="../../../assets/img/LogoAjuntamentSabadell.png" alt="Imatge Ajuntament de Sabadell" width="126"></div>
            <div style="display: block; position: relative; padding: 10px"><img src="../../../assets/img/UE_Feder.png" alt="Imatge FEDER" width="270" height="55"></div>
            <div style="display: block; position: relative; padding: 10px"><img src="../../../assets/img/logo-kunak.svg" alt="Imatge kunak" width="100" height="75"></div>
            <div style="display: block; position: relative; padding: 10px"><img src="../../../assets/img/MCV.png" alt="MVC" width="198" height="75"></div>
            <div style="display: block; position: relative; padding: 10px"><img src="../../../assets/img/4SFERA_Original_converted.svg" alt="Imatge 4sfera" width="190" height="75"></div>
        </div>
        <div fxflex fxLayout="row" fxLayoutAlign="space-around center" class="mx-xs-2 mx-sm-2 mx-md-4 mx-5" >
           <p class="genericTextSabadellSub">{{'FOOTER.DESCRIPTION' | translate}}</p> 
        </div>
    </div>
</div>


