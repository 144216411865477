<div id="mapContainer" *ngIf="!this.mapDataFetching">
    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="mapStyles" (mapReady)="onMapReady($event)">
        <agm-marker *ngFor="let station of mapData" [latitude]="station.webLat" class="custom-marker"
        [longitude]="station.webLong"  [iconUrl]="station.url"
        (markerClick)="goToStation(station.stationID)" (mouseOver)="infoWindow.open();" (mouseOut)="infoWindow.close()">
        <agm-info-window #infoWindow>
            <div>
            <div *ngIf="station.index_NO2 != 0"class="map-info"> {{'MAIN.AIR_QUALITY_ZONE' | translate}} <span [innerHTML]="station.webName"></span> {{'MAIN.IS' | translate}} {{ levels[station.index_NO2] | translate }} </div>
            <div *ngIf="station.index_NO2 == 0" style="padding:10px" fxLayout="column wrap" fxLayoutAlign="center center">{{ levels[station.index_NO2] | translate }} {{'MAIN.AT' | translate}} <span [innerHTML]="station.webName"></span></div>
            <div *ngIf="station.index_NO2 != 0">{{'MAIN.TIME_AND_DATE' | translate : {time : station.date, hour: station.time}  }}</div>
            </div>
        </agm-info-window>
        </agm-marker>
        <agm-map-type-control>
            <button mat-raised-button agmMapTypeControlId="roadmap">Map</button>
            <button mat-raised-button agmMapTypeControlId="satellite">Satellite</button>
        </agm-map-type-control>
        <ng-template #customLegend> 
        </ng-template>

    </agm-map>
    <div id="legend-container">
        <div class="legend-toggle" (click)="toggleLegend()">
            <div class="hamburger">
                <span style="background-color: gainsboro;"></span>
                <span style="background-color: gainsboro;"></span>
                <span style="background-color: gainsboro;"></span>

            </div>
        </div>
    <div id="legend-content">
        <agm-legend [position]="bottomright" [title]="Leyenda" [content]="customLegend"></agm-legend>
        <div style="display: flex;">
            <div class="legend-label-title" style="padding-top: 5px; padding-left: 45px; font-weight:800 ;margin-left: 15px; color: #93B29F;">{{ 'AIRQUALITYLEVELS.LEGEND' | translate }}</div>
                <a [routerLink]="['/about_air/', {outlets: {'about_airRouter': ['airQualitiyLevel']}}]" > <img src="../../../assets/img/info.png" style="height: 20px;width: 20px; margin-left: 30px; margin-top:5px"></a>
        </div>
        <div id="content_" class="hamburger">
            <div class="legend-item" >
            </div>
            <div class="legend-item" >
                <div class="legend-label-subtitle" [innerHTML]="'AIRQUALITYLEVELS.N02_BASED' | translate"></div>
            </div>
            <div class="legend-item" style="margin-top: 10px;">
            <div class="legend-icon icon-dark-blue">1</div>
            <div class="legend-label">{{ levels[1] | translate }}</div>
            </div>
            <div class="legend-item">
            <div class="legend-icon icon-blue">2</div>
            <div class="legend-label"> {{ levels[2] | translate }}</div>
            </div>
            <div class="legend-item">
                <div class="legend-icon icon-yellow">3</div>
                <div class="legend-label">{{ levels[3] | translate }}</div>
            </div>
            <div class="legend-item">
                <div class="legend-icon icon-orange">4</div>
                <div class="legend-label">{{ levels[4] | translate }}</div>
            </div>
            <div class="legend-item">
                <div class="legend-icon icon-red">5</div>
                <div class="legend-label">{{ levels[5] | translate }}</div>
            </div>
            <div class="legend-item">
                <div class="legend-icon icon-dark-red">6</div>
                <div class="legend-label">{{ levels[6] | translate }}</div>
            </div>
            <div class="legend-item">
                <div class="legend-icon icon-none"></div>
                <div class="legend-label">{{ levels[0] | translate }}</div>
            </div>

            <div style="width: 200px; margin-top:26px; margin-left: 14px; height: 30px !important; display: flex;">
                <div style="margin-right: 8px; border: 2px; border-color: black; border-style: solid; margin-top: 16px; transform: translate(0%, -50%);" class="circleSmall"></div>
                <div class="legend-label"> {{ 'AIRQUALITYLEVELS.REFERENCE_EQUIPMENT' | translate }}</div>
            </div>
            <div class="legend-item" style="margin-top:2px; margin-left: 14px;">
                <div class="hex" style="margin-right: 8px;">
                    <div class="hex-background"></div>
                    </div>
                <div class="legend-label">{{ 'AIRQUALITYLEVELS.SENSOR' | translate }}</div>
            </div>
        </div>
        
    </div>
</div>
</div>

<div *ngIf="this.mapDataFetching">
    <div fxLayout="row" style="margin-top: 18%; padding-top: 10px; padding-bottom: 10px;" fxLayoutAlign="start stretch">
        <div fxFlex="20" fxFlex.lg="10" fxFlex.md="2" fxFlex.sm="2" fxFlex.xs="2"></div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <div fxFlex="20" fxFlex.lg="10" fxFlex.md="2" fxFlex.sm="2" fxFlex.xs="2"></div>
    </div>
</div>
