import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportTableAsExcelFile(data: any, excelFileName: string){
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsCSV(data: {}, headers: any[], sortedDates: any[], excelFileName: string, separator: string): void {

    console.log(separator);

    let csvArray = "StartDate(utc)" + separator + "EndDate(utc)"
    for(let header of headers){
      csvArray += separator + header
    }
    csvArray += "\r\n"

    console.log(csvArray);

    for(let currentDate of sortedDates){

      let dates = currentDate.split("_")

      for(let currentDate of dates) {
        console.log("Current date", currentDate)
        let splitted = currentDate.split(' ')
        let formattedDate = splitted[0]
        csvArray += formattedDate
        csvArray += separator
      };
      csvArray = csvArray.slice(0, -1)

      for(let header of headers){
        let curr: String = data[currentDate][header]
        if(curr == undefined) {
          curr = ""
        }
        curr = curr + "";

        if(separator == ";"){
          curr = curr.replace('.', ',')
        }
        
        csvArray += separator + curr
      }
      csvArray += "\r\n"
    }


    var blob = new Blob([csvArray], {type: 'text/csv' })
    FileSaver.saveAs(blob, excelFileName+".csv");
    
    //new AngularCsv(data, excelFileName, options)
  }
}
