<div fxLayout="row" id="appHeader" fxLayoutAlign="end center">
    <div class="mr-4" fxFlex fxLayoutAlign="end center" fxLayoutGap="15px">
        <a style="color: gray;" (click)="changeLanguage('ca-ES')" class="lang-icon" >
            CA
        </a>
        <a style="color: gray;" (click)="changeLanguage('es-ES')" class="lang-icon">
            ES
        </a>
        <a (click)="goToLink('https://www.facebook.com/ajuntamentdesabadell')">
            <i class="fa-brands fa-facebook" style="color: #cbcdd2;"></i>
        </a>
        <a class="uk-icon-hover uk-icon-twitter uk-icon-small" (click)="goToLink('https://twitter.com/Aj_Sabadell')">
            <i class="fa-brands fa-twitter" style="color: #cbcdd2;"></i>
        </a>

        
    </div>
</div>