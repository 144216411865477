import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pollen-side-bar',
  templateUrl: './pollen-side-bar.component.html',
  styleUrls: ['./pollen-side-bar.component.css']
})
export class PollenSideBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  selectedMenu(url:string):boolean{
    return location.pathname.includes(url)
  }  

}
