import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-csv',
  templateUrl: './dialog-csv.component.html',
  styleUrls: ['./dialog-csv.component.css']
})
export class DialogCsvComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogCsvComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogCSVConfirmData) { }

  ngOnInit() {
  }
}

export interface DialogCSVConfirmData {
  title: string;
  question: string;
  option1: string;
  option2: string;
}
