<div class="repots-container sabadell-Container">
    <div class="genericTitleSmall">{{'REPORT.REPORT' | translate }}</div>
    <p *ngIf="InformeList.length==0">{{'REPORT.NO_DOCUMENT'| translate}}</p>
    <div class="files-container" >
            <div *ngFor="let informe of InformeList">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"> 
                    <img class="icon" src="../../../assets/img/blank-file-outline-icon.svg">
                    <a style="text-decoration: none; color: gray;" href={{informe.path}} target="_blank" fxLayout="row" fxLayoutAlign="start center"> 
                        <p style="display: flex;" class="genericTextSabadell">{{informe.title}}</p>
                        <img style="margin-left: 2vw;" class="icon" src="../../../assets/img/download-install-line-icon.svg">
                    </a>
                </div>
                <hr>
        </div>
    </div>
</div>