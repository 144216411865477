<div class="dialog-container">
    <h2 style="text-align: center;font-weight: bold; color:#878181;">Informació de l'ús de cookies</h2>
    <div class="info-container">
        <p>Les cookies són fitxers que es descarreguen en els ordinadors en accedir a determinades pàgines web. Les cookies són utilitzades per les pàgines web per diferenciar entre els diferents usuaris que hi accedeixen i les informacions obtingudes puguin ser recuperades en posteriors visites.</p>
        <p> Les cookies ens permeten entre d'altres coses:</p>
        <ul>
            <li>Guardar el nom d'usuari i contrasenya per evitar tornar-ho a introduir</li>
            <li>Obtenir informació sobre els hàbits de navegació de l'usuari.</li>
            <li>Utilitzar opcions de continguts o disseny escollides anteriorment.</li>
            <li>Obtenir informació de l'ordinador del visitant, com l'adreça IP, sistema operatiu o tipus de navegador</li>
            <li>En cap cas s'obtenen dades sobre el nom, cognoms o adreça postal des de la qual l'usuari s'ha connectat</li>
        </ul>
        <p>Les cookies generalment s'esborren en tancar el navegador, però per tal de poder d'aprofitar al màxim les seves funcionalitats, algunes d'aquestes persisteixen en el disc dur de l'ordinador i axí poder ser d'utilitat en futures sesions.</p>
        <p>Tanmateix, vostè pot permetre, bloquejar o eliminar les cookies instal·lades en el seu equip mitjançant la configuració de les opcions del seu navegador. Pot trobar informació sobre com fer-ho, en relació amb els navegadors més comuns, en els links que s'inclouen a continuació:</p>
        
        <uL>
            <li>Explorer: <a href="https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></li>
            <li>Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
            <li>Chrome: <a href="https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></li>
            <li>Safari: <a href="https://support.apple.com/kb/ph5042">https://support.apple.com/kb/ph5042</a></li>
        </uL>
        <p>Li informem, no obstant això, de la possibilitat que la desactivació d'alguna cookie impedeixi o dificulti la navegació o la prestació dels serveis oferts en aquesta Web.</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button id="btn-close"  mat-button mat-dialog-close >Tancar</button>
    </div>
</div>
