import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogConfirmComponent } from 'src/app/components/dialog-confirm/dialog-confirm.component';
import { AboutWebService } from 'src/app/services/about-web.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.css']
})

export class AccessibilityComponent implements OnInit {

  data; 
  constructor(
    private aboutWebService: AboutWebService, 
    private _sanitizer: DomSanitizer,
    private languageService : LanguageService) 
    { }

  ngOnInit(): void {

    let lang = this.languageService.getCurrentLanguage();
    this.getData(lang);
    this.languageService.newLanguageSelected.subscribe(res =>{
        lang = res;
        this.getData(lang);      
  })
  }

  getData(lang: string){
    this.aboutWebService.getData("accessibility", lang).subscribe(res =>{
      this.data = this._sanitizer.bypassSecurityTrustHtml(res);
    })
  }

}
