import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  actualDate:Date
  showInput: Boolean = false;
  selectedButton: string
  searchInput: string;
  @ViewChild('navbarToggler') navbar: ElementRef;
  constructor( private route: Router, private dialog: MatDialog, private router : Router) { }

  @ViewChild('myname') input; 
  ngOnInit(): void {
    this.actualDate = new Date()
  }

  goMain(){
    window.location.href = "/"
  }

 

  closeNavbar(route : string) {
    if (this.navbar.nativeElement.classList.contains('show')) {
          this.navbar.nativeElement.classList.toggle('show');
      }
      
    this.router.navigate([route]); 

  }


  goToLink(url: string){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: "",
        question: "Està a punt de sortir, vol continuar?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "yes") {
        window.open(url, "_blank");
      }
    })
  }

  selectedMenu(url:string):boolean{
    return location.hash.includes(url)
  }

  isMain():boolean{
    return location.pathname == "/" && location.hash == "#/"
  }

  redirect(url){
    if(url == "/"){
      this.goMain()
    }
    else this.route.navigate([url]);
  }
  showSeachInput(){
    if(!this.showInput){
      this.showInput = true;
    }
    else{
      console.log(this.searchInput);
    }
  }


}
