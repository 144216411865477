<div class="p-4 side-container" style="background-color: #f7f6f6;">
    <div class="genericTitleSmall"><span style="color: black;">{{'ABOUT_AIR.ABOUT_AIR' | translate}}</span></div>

    <div fxLayout="column">
        <button [routerLink]="[{outlets: {'about_airRouter': ['pollution']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_air/(about_airRouter:pollution)') ||  selectedMenu('/about_air')}">
            <div style="background-color: #f7f6f6;" class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'AIR_CONTAMINANTS.CONTAMINANTS' | translate}}</a>
            </div>
        </button>
        <hr>

        <button [routerLink]="[{outlets: {'about_airRouter': ['regulations']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_air/(about_airRouter:regulations)')}">
            <div style="background-color: #f7f6f6;" class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'AIR_REGULATIONS.REGULATIONS' | translate}}</a>
            </div>
        </button>
        <hr>

        <button [routerLink]="[{outlets: {'about_airRouter': ['measuring_equimpent']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_air/(about_airRouter:measuring_equimpent)')}">
            <div style="background-color: #f7f6f6;"class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'ABOUT_AIR.MEASURING_EQUIMPENT' | translate}}</a>
            </div>
        </button>
        <hr>

        
        <button [routerLink]="[{outlets: {'about_airRouter': ['airQualitiyLevel']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_air/(about_airRouter:airQualitiyLevel)')}">
            <div style="background-color: #f7f6f6;" class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'ABOUT_AIR.AIR_QUALITY_LEVEL' | translate}}</a>
            </div>
        </button>

    </div>
</div>
