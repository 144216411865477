<div class="border-shadow sabadell-Container main-container" fxLayout="column" fxLayoutAlign="center start">
    <h2 class="genericTitle bold custom-padding">{{'MAIN.AIR_QUALITY_SABADELL' | translate}}</h2>
    <div *ngIf="!dataNotUpdated && realtimeDateData" class="genericTextSabadell custom-padding" style="font-weight: bold; padding-bottom: 5px">{{date}} {{realtimeDateData.textBetweenDateTime}} {{time}}h</div>
    <div  class="genericTextSabadell" *ngIf="dataNotUpdated" [innerHTML]="realtimeDateData.oldDataMessage" style="font-weight: bold; padding-bottom: 5px;"></div>
    <app-map style="width: 100%; height: 640px;" id="map"></app-map>
    <p class="genericTextSabadell custom-padding">
        <b>{{'MAIN.NOTE' | translate}} </b>
        <span [innerHtml]="'MAIN.NOTE_DESCRIPTION' | translate" style="margin-top: 0; margin-left: 10px;"></span>
    </p>
</div>
<div id="meteo_main" class="genericTextSabadell bold sabadell-Container" fxLayout="row wrap" fxLayoutAlign="center start" style="margin-top: 20px; padding: 10px; display: flex;">
    <div fxLayoutAlign="start center" style="display:flex; margin-left: 0.2vw;">
            <span>{{'MAIN.METEOROLOGICAL_DATA' | translate}}</span>
    </div>
    <div fxLayoutAlign="space-around center" class="meteo-data-container" >
        <span><img src="../../../assets/img/thermometer-icon.png" class="icons" alt="Thermometer-image"> {{temp}} ºC</span>
        <span><img src="../../../assets/img/water-drop-teardrop-icon.png" class="icons">{{humitat}} %</span>
        <span *ngIf="vent === '-'"><img src="../../../assets/img/wind-icon.png" class="icons"> - (km/h) </span>
        <span *ngIf="vent !== '-'"><img src="../../../assets/img/wind-icon.png"  class="icons"> {{vent}} km/h (SE)</span>
    </div>
</div>
<div *ngIf="webEpisode.episode && webEpisode.type == 'Avís preventiu'" class="border-shadow alert-container">
    <div  fxLayout="column" fxLayoutAlign="center center">
        <div class="border-shadow alert" fxLayout="row" fxLayoutAlign="start center" style="background-color: #FFE393;">
            <div><img src="../../../assets/img/exclamation-mark-in-a-circle.png" style="width:80px;" alt="warning-sign"></div>
            <div style="margin-left:30px">
                <h3>{{'MAIN.PREVENTIVE_WARNING' | translate}}</h3>
                <h3>{{'MAIN.AIR_POLLUTION_EPISODE' | translate}}</h3>
            </div>
        </div>
    </div>
    <div class="mx-xs-2 mx-lg-5">
        <p class="genericTextSabadell" style="color:#a3c2b2 ;font-weight:bold" [innerHTML]="'MAIN.WARNING_MODERATE_LEVEL_PM10' | translate"></p>
        <p class="genericTextSabadell" [innerHTML]=webEpisode.text></p>
        <button class="btn-information btn-avis"><a target="_blank" href="https://mediambient.gencat.cat/ca/05_ambits_dactuacio/atmosfera/qualitat_de_laire/qualitat-de-laire-a-la-conurbacio-de-barcelona/pla_millora_qua_aire_2011_2015/mesures-del-pamqa/episodis_ambientals/">{{'MAIN.MORE_INFORMATION' | translate}}</a></button>
    </div>
</div>
<div *ngIf="webEpisode.episode && webEpisode.type != 'Avís preventiu'" class="border-shadow alert-container">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="border-shadow alert" fxLayout="row" fxLayoutAlign="start center" style="background-color: #ff4444;">
            <div><img src="../../../assets/img/exclamation-mark-in-a-circle.png" style="width:80px;" alt="warning-sign"></div>
            <div style="margin-left:30px">
                <h3>{{'MAIN.WARNING' | translate}}</h3>
                <h3>{{'MAIN.AIR_POLLUTION_EPISODE' | translate}}</h3>
            </div>
        </div>
    </div>
    <div class="mx-xs-2 mx-lg-5">
        <p class="genericTextSabadell" style="color:#a3c2b2; font-weight:bold" [innerHTML]="'MAIN.WARNING_HIGH_LEVEL_PM10' | translate"></p>
        <p class="genericTextSabadell" [innerHTML]=webEpisode.text></p>
        <button class="btn-information btn-avis"><a target="_blank" href="https://mediambient.gencat.cat/ca/05_ambits_dactuacio/atmosfera/qualitat_de_laire/qualitat-de-laire-a-la-conurbacio-de-barcelona/pla_millora_qua_aire_2011_2015/mesures-del-pamqa/episodis_ambientals/">{{'MAIN.MORE_INFORMATION' | translate}}</a></button>
    </div>
</div> 