import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable} from 'rxjs';
import { Injectable } from "@angular/core";
import { Informe } from "../models/informe";

@Injectable({
    providedIn: 'root'
  })

  export class InformeSerive {
    private _baseUrl: string;

    constructor(private http: HttpClient) {
        this._baseUrl = `${environment.apiUrl}`;
    }

    getInformes(lang: string): Observable<Informe[]>{
        const params = new HttpParams()
        .set('lang', lang)
      return this.http.get<Informe[]>(this._baseUrl + "/WebInformes", {params});
    }
  }