import { Component, OnInit } from '@angular/core';
import { Informe } from 'src/app/models/informe';
import { InformeSerive } from 'src/app/services/informe.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  documnetUrl: string;
  InformeList: Informe[] = []
  constructor(private informeService: InformeSerive, private languageService : LanguageService) { }

  ngOnInit(): void {
    this.documnetUrl = `${environment.documentUrl}`;
    let lang = this.languageService.getCurrentLanguage();
    this.getData(lang);
    this.languageService.newLanguageSelected.subscribe(res =>{
      this.getData(res);
    })
  }

  getData(lang: string){
    this.informeService.getInformes(lang).subscribe(res =>{
    res.map(file =>{
      let filename = file.path.split("/");
      file.path= this.documnetUrl+"/" + filename[filename.length - 1];
    })
    this.InformeList = res;
    });
  }

}
