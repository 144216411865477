<div fxFill fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh; background-color: #E9ECEB;">
    <app-header class="mt-4" style="width: 90%;"></app-header>
    <app-nav-bar style="width: 90%;" class="mb-4"></app-nav-bar>
    <div style="width: 90%;">
        <router-outlet  (activate)="onActivate($event)"></router-outlet>
    </div>
    <app-footer  style="width: 90%;" class="footer"></app-footer>
</div>

<mat-card *ngIf="showCookieModal" class="mat-elevation-z8" class="cookie-consent" fxLayout="column" fxLayoutAlign="center center">
    <div class="p-1 genericTitleSmall" style="color:rgb(82, 80, 80); text-align: center;">{{'COOKIES.COOKIES_DESC' | translate}}</div>
    <div class="p-1" style="font-size: medium; text-align: center;">{{'COOKIES.COOKIES_ACCEPT_INFO' | translate}} <button class="btn-link" (click)="openDialogInfoCookies()">{{'COOKIES.MORE_INFORMATION' | translate}} </button></div>

    <div fxLayout="row" style="width: 100%;" fxLayoutAlign="center center">
        <!-- <button class="allow-button" mat-raised-button button (click)="this.showCookieModal = false">Cancel·lar</button> -->
        <button class="btn-information" style="color:white; font-weight: normal; font-size: large; width:130px; font-size:20px " mat-raised-button button (click)="acceptCookies()">{{'ACCEPT' | translate}}</button>
    </div>
</mat-card>