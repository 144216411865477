import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from '../app-config';
import { Observable} from 'rxjs';
import { Station } from '../models/station';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StationData } from '../models/stationData';
import { LatestStationData } from '../models/LatestStationData';
import { WebUtils } from '../models/WebUtils';
import { MeteoData } from '../models/meteoData';


@Injectable({
    providedIn: 'root'
  })

  export class StationService {
    private _baseUrl: string;
  
    constructor(private http: HttpClient) {
      this._baseUrl = `${environment.apiUrl}`;
    }

    getStations(lang: string): Observable<StationData[]>{
      const params = new HttpParams()
      .set('lang', lang)
      return this.http.get<StationData[]>(this._baseUrl + "/WebMap/allStations", {params});
    }


    getLatestData(stationID: string, lang: string): Observable<LatestStationData[]>{
      const params = new HttpParams()
      .set('stationID', stationID)
      .set('lang', lang);
      return this.http.get<LatestStationData[]>(this._baseUrl + '/LatestDataItem', {params});
    }
    
    getMeteoData(): Observable<MeteoData>{
      return this.http.get<MeteoData>(this._baseUrl + "/Meteo");
    }



    getStationByID(stationID: string, lang: string): Observable<Station>{
      const params = new HttpParams()
      .set('stationID', stationID)
      .set('lang', lang);
      return this.http.get<Station>(this._baseUrl + '/Station/stationByID', { 'headers': {
        "stationID": stationID,
        "lang": lang
      }})
      .pipe(
        map(
          (res: Station) => {
              return res;
          },
          (error: any) => {
              console.log("Get stats error: " + JSON.stringify(error))
          }
        ));
    }

    getMapDataByID(stationId:string, lang: string): Observable<any>{
      return this.http.get<any>(this._baseUrl + '/WebMap', { 'headers': {
        "stationID": stationId,
        "lang": lang
      }
    }).pipe(map(
        (res: any) => {
            return res;
        },
        (error: any) => {
            console.log("Get map data error: " + JSON.stringify(error))
        }
      ));
    }
    getWebUlits(lang: string) :  Observable<WebUtils>{
      return this.http.get<WebUtils>(this._baseUrl + '/WebUtils/' +  lang).pipe(map(
        (res: any) => {
            return res;
        },
        (error: any) => {
            console.log("Get web utils error: " + JSON.stringify(error))
        }
      ));
    }

    
  }
  