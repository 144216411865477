import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {

  mapsDescription:string = "";

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.get('MAPS.DESCRIPTION', {}).subscribe((res: string) => {this.mapsDescription = res})
  }

}
