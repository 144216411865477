<div class="genericTitleSmall"><span style="color: #808080;">{{'THE_AIR' | translate}}</span> {{ 'IN_ANDORRA' | translate}}</div>
<hr>

<div fxLayout="column">
    <button routerLink="/pollen" color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen') && !selectedMenu('/pollen/about_pollen') && !selectedMenu('/pollen/pollen_calendar') && !selectedMenu('/pollen/measurement') && !selectedMenu('/pollen/history') && !selectedMenu('/pollen/levels') && !selectedMenu('/pollen/alerts-and-predictions') && !selectedMenu('/pollen/year_data') && !selectedMenu('/pollen/extra_info')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a [ngClass]="{'selected-menu': selectedMenu('/pollen') && !selectedMenu('/pollen/about_pollen') && !selectedMenu('/pollen/pollen_calendar') && !selectedMenu('/pollen/measurement') && !selectedMenu('/pollen/history') && !selectedMenu('/pollen/levels') && !selectedMenu('/pollen/alerts-and-predictions') && !selectedMenu('/pollen/year_data') && !selectedMenu('/pollen/extra_info')}">{{'POLLEN_NET.POLLEN_NET' | translate}}</a>
            <span class="chevron"></span>
        </div>
    </button>
    <button routerLink="/pollen/pollen_calendar" color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/pollen_calendar')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a [ngClass]="{'selected-menu': selectedMenu('/pollen/pollen_calendar')}">{{'POLLEN_CALENDAR.POLLEN_CALENDAR' | translate}}</a>
            <span class="chevron"></span>
        </div>
    </button>
    <button routerLink="/pollen/year_data" color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/year_data')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a [ngClass]="{'selected-menu': selectedMenu('/pollen/year_data')}">{{'POLLEN.YEAR_DATA' | translate}}</a>
            <span class="chevron"></span>
        </div>
    </button>
    <button routerLink="/pollen/extra_info" color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/extra_info')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a [ngClass]="{'selected-menu': selectedMenu('/pollen/extra_info')}">{{'POLLEN.MORE_INFO' | translate}}</a>
            <span class="chevron"></span>
        </div>
    </button>

    <button routerLink="/pollen/alerts-and-predictions" color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/alerts-and-predictions')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a [ngClass]="{'selected-menu': selectedMenu('/pollen/alerts-and-predictions')}">{{'POLLEN_PREDICTIONS_ALERTS.PREDICTIONS_ALERTS' | translate}}</a>
            <span class="chevron"></span>
        </div>
    </button>

<!--

    <button routerLink="/pollen" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen') && !selectedMenu('/pollen/about_pollen') && !selectedMenu('/pollen/pollen_calendar') && !selectedMenu('/pollen/measurement') && !selectedMenu('/pollen/history') && !selectedMenu('/pollen/levels') && !selectedMenu('/pollen/alerts-and-predictions')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'POLLEN_NET.POLLEN_NET' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    <button routerLink="/pollen/about_pollen" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/about_pollen')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'ABOUT_POLLEN.ABOUT_POLLEN' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    <button routerLink="/pollen/pollen_calendar" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/pollen_calendar')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'POLLEN_CALENDAR.POLLEN_CALENDAR' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    <button routerLink="/pollen/measurement" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/measurement')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'POLLEN_MEASUREMENT.MEASUREMENT' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    <button routerLink="/pollen/history" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/history')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'POLLEN_HISTORY.HISTORY' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    <button routerLink="/pollen/levels" mat-button color="primary" [ngClass]="{'selected-menu': selectedMenu('/pollen/levels')}">
        <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
            <a>{{'POLLEN_LEVELS.LEVELS' | translate}}</a>
            <i class="fas fa-chevron-right"></i>
        </div>
    </button>
    -->
</div>