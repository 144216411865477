import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AboutWebService } from 'src/app/services/about-web.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-legal-warn',
  templateUrl: './legal-warn.component.html',
  styleUrls: ['./legal-warn.component.css']
})
export class LegalWarnComponent implements OnInit {

  data;
  constructor(
    private aboutWebService: AboutWebService, 
    private _sanitizer: DomSanitizer,
    private languageService : LanguageService) 
    { }

  ngOnInit(): void {

    let lang = this.languageService.getCurrentLanguage();
    this.getData(lang);
    this.languageService.newLanguageSelected.subscribe(res =>{
        lang = res;
        this.getData(lang);  
  })
  }

  getData(lang : string){
    this.aboutWebService.getData("legal_warn", lang).subscribe(res =>{
      this.data = this._sanitizer.bypassSecurityTrustHtml(res);
    })
  }

}
