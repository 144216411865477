<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid m-0 mx-3">
    <div (click)="goMain()"><img src="../../../assets/img/Aj_sabadell.png"></div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02" #navbarToggler>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <div style="margin-left: 20px;" [ngClass]="{'selectedButton': isMain()}" (click)="goMain()" color="primary" class="navButton">{{'MAIN.MAIN' | translate }}</div>
        </li>
        <li class="nav-item">
          <div [ngClass]="{'selectedButton': selectedMenu('/about_air')}" (click)="closeNavbar('about_air')" color="primary" class="navButton">{{'ABOUT_AIR.ABOUT_AIR' | translate }}</div>
        </li>
        <li class="nav-item">
          <div [ngClass]="{'selectedButton': selectedMenu('/data_and_statistics_home')}" (click)="closeNavbar('data_and_statistics_home')" color="primary" class="navButton">{{'DATA.DATA' | translate }}</div>
        </li>

        <li class="nav-item">
          <div [ngClass]="{'selectedButton': selectedMenu('/reports')}" (click)="closeNavbar('reports')" color="primary" class="navButton">{{'REPORT.REPORT' | translate }}</div>
        </li>

        <li class="nav-item">
          <div [ngClass]="{'selectedButton': selectedMenu('/about_web')}" (click)="closeNavbar('about_web')" color="primary" class="navButton">{{'ABOUT_WEB.ABOUT_WEB' | translate }}</div>
        </li>
      </ul>
      <!--<form class="d-flex justify-content-center align-items-center">
        <mat-form-field [hidden]="!showInput" floatLabel="never">
         <<input matInput placeholder="{{'SEARCH' | translate}}" [(ngModel)]="searchInput" name="search">
        </mat-form-field>
    
      </form>-->
    </div>
  </div>
</nav>
