import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private dialog: MatDialog, private languageService : LanguageService, private translateService: TranslateService,) { }

  currentDate = ""
  titleOutText: string = "";

  ngOnInit(): void {

    const d = new Date();
    let momentDate = moment(d)
    //momentDate.locale('ca-ES')

    let diaSetmana = momentDate.format('dddd'); 

    diaSetmana = diaSetmana[0].toUpperCase() + diaSetmana.substring(1)

    let diaMes = momentDate.format('DD'); 

    let mes = momentDate.format('MMMM'); 

    mes = mes[0].toUpperCase() + mes.substring(1)

    let any = momentDate.format('YYYY'); 

    this.currentDate = diaSetmana + ", " + diaMes + " " + mes + " " + any
    this.translateService.get('MAIN.HEADER_OUT', {}).subscribe((res: string) => {this.titleOutText = res;})
  }

  goToLink(url: string, skip = false){
    if(!skip){
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: "",
          question: this.titleOutText,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "yes") {
          window.open(url, "_blank");
        }
      })
    }
    else {
      window.open(url, "_blank");
    }
  }
  changeLanguage(language: string){
    console.log("setlang", language)
      this.languageService.setCurrentLanguage(language);
   //   console.log(this.languageService.getCurrentLanguage())
  }

}
