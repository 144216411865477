import { Injectable } from "@angular/core"

@Injectable()
export class Helper {

    constructor() { }

    public static getCalendarFormattedDate(currentDate: Date){
        let month = (currentDate.getMonth()+1)
        let day = currentDate.getDate()

        let monthStr = month.toString()
        let dayStr = day.toString()

        if(month < 10)
        monthStr = "0" + month
        
        if(day < 10)
        dayStr = "0" + day

        return currentDate.getFullYear() + "-" + monthStr + "-" + dayStr
    }

}