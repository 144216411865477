<div fxLayout="column" style="margin-bottom: 100px;">
    <div fxFill>
        <h1>Mapes</h1>
        <hr>
        <div [innerHTML]="mapsDescription"></div>
        <!-- <p>A partir dels mesuraments de les estacions de vigilància de la qualitat de l'aire i de dades complementries relacionades com el trànsit, els paràmetres meteorològics i/o la configuració del trànsit, el Departament de Medi Ambient utilitza models
            de càlcul per representar els nivells de qualitat de l'aire per a cada nucli urbà.</p>
        <p><a href="">Cliqueu aquí per visualitzar i descarregar els diferents mapes disponibles.</a></p>

        <p>En aquesta pàgina es poden visualitzar i descarregar els mapes per zones, per anys i per contaminant definits per:</p>

        <ul>
            <li>Mapes d'implantació: són els mapes que representen la situació de les estacions de vigilància de la qualitat de l'aire (xarxa manual i xarxa automàtica). </li>
            <li>Mapes d'immissió: són els mapes que recullen els resultats dels mesuraments dels nivells de qualitat de l'aire. </li>
            <li>Mapes d'interpolació: són els mapes fets a partir de mesuraments que determinen els nivells de la qualitat de l'aire en zones on no es mesura directament. </li>
            <li>Mapes de modelització: són els mapes fets a partir de dades (trànsit, configuració urbana, etc.) que determinen els nivells de la qualitat de l'aire en zones edificades.</li>
        </ul> -->
        <br>
    </div>
</div>
<br>