import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AboutAirService } from 'src/app/services/about-air.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.css']
})
export class RegulationComponent implements OnInit {

  lang = "ca-ES";
  data;

  constructor(
    private aboutAirService: AboutAirService, 
    private _sanitizer: DomSanitizer,
    private languageService : LanguageService) 
    { }

  ngOnInit(): void {

    let lang = this.languageService.getCurrentLanguage();
    this.getData(lang);
    this.languageService.newLanguageSelected.subscribe(res =>{
        lang = res;
        this.getData(lang);     
  })
  }
    getData(lang : string){
      this.aboutAirService.getData("regulations", lang).subscribe(res =>{
        this.data = this._sanitizer.bypassSecurityTrustHtml(res);
      })
    }

}
