import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexStroke,
  ApexTooltip,
  ApexAnnotations
} from "ng-apexcharts";
import { WebService } from 'src/app/services/web.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';
import { Helper } from '../helper';
import { MatDialog } from '@angular/material/dialog';
import { DialogCsvComponent } from 'src/app/components/dialog-csv/dialog-csv.component';
import { StationService } from 'src/app/services/station.service';
import { LatestStationData } from 'src/app/models/LatestStationData';
import { Station } from 'src/app/models/station';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { PlotData } from 'src/app/models/plotData';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  colors: string[];
  annotations: ApexAnnotations;
};

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("chart2") chart2: ChartComponent;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;

  limit: number;
  data: string;
  selectedStation : Station;
  // lang = 'ca-ES'
  stationAQIData = undefined
  pollutantList: [string, string, string][] = []
  pollutantLang: [string][] = []
  daysSelected = 14
  plotFetching = true
  pollutants = new FormControl()
  csvData = {}
  csvHeaders = []
  sortedDates = []
  stationName = ""
  latestDataFetching = true
  form: FormGroup;
  oldValues: any = undefined
  selectedMapData = undefined
  previousXAxis = undefined
  removeDisable = false
  //backButtonEnabled = '0'
  graph_title : string = " ";
  graph_Serie_Title : string = "";
  plotData : PlotData[] = [];
  lang: string
  info: string
  question: string
  comma: string
  dot: string


  latestData: LatestStationData[] = []
  constructor(
    private webService: WebService,
    private stationService: StationService,
    private fb: FormBuilder,
    private excelService:ExcelService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private dialog: MatDialog) {
    this.form = fb.group({
      pollutants: [this.pollutants, Validators.required]
    });
  }

  ngOnInit(): void {
   //this.langWeb = this.languageService.getCurrentLanguage();
    this.lang = this.languageService.getCurrentLanguage();
    this.getData(this.lang);
    this.translateService.get('STATION.CHARTLIMIT', {}).subscribe((res: string) => {this.graph_Serie_Title = res;})
    this.translateService.get('WEBMAP.HOURLY_CONCENTRATION', {}).subscribe((res: string) => {this.graph_title = res})
    this.translateService.get('WEBMAP.HOURLY_CONCENTRATION', {}).subscribe((res: string) => {this.graph_title = res})
    this.languageService.newLanguageSelected.subscribe(res =>{
        this.lang = this.languageService.getCurrentLanguage();
        this.getData(this.lang);
        this.translateService.get('WEBMAP.HOURLY_CONCENTRATION', {}).subscribe((res: string) => {this.graph_title = res})
        this.translateService.get('STATION.CHARTLIMIT', {}).subscribe((res: string) => {this.graph_Serie_Title = res;})
    })

  }

  getData(lang : string){
    this.route.queryParamMap.subscribe((params) => {
      let stationID : string = "";
      stationID = params.get('stationId');
      if(stationID){
        this.stationService.getStationByID(stationID, lang).subscribe((station=>{
          if(station){
            this.selectedStation = station[0];
            this.getStationAQIData()
            this.getPollutants()
            this.getLatestData(lang)
            this.showLastDays(this.daysSelected)
          }
        }))

      } else {
        window.location.href = "/"
          localStorage.removeItem("SelectedStation")
        localStorage.removeItem("SelectedMapData")
      }
    })
  }

  checkDate(lastDate: string, differenceLimit: number){
    let lastDataDate :Date  = new Date(lastDate);
    let currentDate : Date= new Date();
    const diffInHours = Math.abs(currentDate.getTime() - lastDataDate.getTime()) / 3600000;
    return diffInHours > differenceLimit
  }

  getStationAQIData(){
    this.webService.getStationAQIData('_band_CAT', this.selectedStation.stationId).subscribe(
      res => {
        this.stationAQIData = res;
      },
      error => {
        this.stationAQIData = undefined;
      }
    )
  }

  getPollutants(){
    this.webService.getPollutants(this.selectedStation.stationId).subscribe((res) => {
      if(res){
        let lang = this.languageService.getCurrentLanguage();
        this.pollutantList = [];
        for(let pollutant of res){
          if (lang == 'ca-ES') {
            this.pollutantList.push([pollutant["pollutantID"], pollutant["notation"], pollutant["web_pollutant_CAT"]])
          } else {
            this.pollutantList.push([pollutant["pollutantID"], pollutant["notation"], pollutant["web_pollutant_ES"]])
          }
        }
        if(this.pollutantList.length === 1){
          this.pollutants.setValue([this.pollutantList[0]]);
        }
        
      } 
      this.showLastDays(this.daysSelected)
    })
  }

  showLastDays(days: number){
    this.daysSelected = days
    let currentDate = new Date()
    let currentDateStrTo = this.formatDate(currentDate, false)
    currentDate.setDate(currentDate.getDate() - days)
    let currentDateStrFrom = this.formatDate(currentDate, true)
    this.getPlotData(currentDateStrFrom, currentDateStrTo)
  }

  getPlotData(dateFrom: string, dateTo: string){
    this.plotFetching = true
      let pollutantIDs = []

      if(this.pollutants.value != undefined) {
        for(let pollutant of this.pollutants.value){
          pollutantIDs.push(pollutant[0])
        }
      }
      if (pollutantIDs.length == 1) {
          switch(pollutantIDs[0]){
            case "0005": {
              this.limit = 40;
              break;
            }
            case "6001":{
              this.limit = 20;
              break;
            }
            case "0007": {
              this.limit = 120;
              break;
            }
            case "0008": {
              this.limit = 40;
              break;
            }
            case "0010": {
              this.limit = 2;
              break;
            }
          } 
      } else {
        this.limit = -1;
      }
      this.webService.getPlotData(dateFrom, dateTo, this.selectedStation.stationId, pollutantIDs).subscribe(
        res => {
          this.plotData = res;
          // Obtenir dates mínimes i màximes en les quals hi ha dades per poder pintar correctament el gràfic
          let minDate: Date = null
          let maxDate: Date =  null
          this.prepareCsvData(res)

          if(this.plotData.length !== 0){

            for(let pollutant of this.plotData){

              for(let elem of pollutant.values){
                let date = new Date(elem["dateLocal"])
                let value = elem["value"]
                if(minDate == null || (date < minDate && value != null)){
                  minDate = date
                }
                if(maxDate == null || (date > maxDate && value != null)){
                  maxDate = date
                }
              }
            }

            let colors = []
            this.webService.getGraphicColors().subscribe((colorResult)=>{
              if(colorResult){
                colors = colorResult
                this.initializeChart(this.plotData, minDate, maxDate, colors)
              }
              this.plotFetching = false
            })
          }
          else {
            this.plotFetching = false
          }
           if(!this.removeDisable){
              this.removeDisable = true
            
            setTimeout(()=>{                           // <<<---using ()=> syntax
          //     //console.log("CARREGAT");
              this.disable()
             }, 1000);
           }
          
        },
        error => {
          this.plotFetching = false
        }
      )
  }

  disable(){
    if(this.chart){
      this.chart.toggleSeries("NOX (ug/m3)")
      this.chart.toggleSeries("NO (ug/m3)")
    }
    if(this.chart2){
      this.chart2.toggleSeries("NOX (ug/m3)")
      this.chart2.toggleSeries("NO (ug/m3)")
    }
  }

  initializeChart(res: PlotData[], dateFrom: Date, dateTo: Date, colors: string[]){
    let seriesData = []

    if(res.length > 0){
      this.stationName = res[0].station

      for(let value of res){
        let name = this.lang == ("ca-ES") ? value.web_pollutant_CAT : value.web_pollutant_ES 
        seriesData.push(
          {
            name: name  + " (" + value.unit +  ")",
            //name: value.component,
            data: this.generateTimeSeries(value.values)
          }
        )
      }
      if (seriesData.length == 1 ){
        
        seriesData.push(
          {
            name: this.graph_Serie_Title,
            data: this.limit,
            color: "#FF0000"
          }
        )
    
      }


    let currDate = new Date()
    let currDateHour = new Date()
    currDateHour.setHours(currDateHour.getHours()+10)

    this.chartOptions1 = {
      series: seriesData,
      chart: {        
        events : {
          beforeZoom : (e, {xaxis}) => {
              let maindifference = currDateHour.valueOf() - currDate.valueOf();
              let zoomdifference =   xaxis.max - xaxis.min ;
              if( zoomdifference < maindifference )
              return this.previousXAxis
              else {
                this.previousXAxis = {
                  xaxis: {
                    min: xaxis.min,
                    max: xaxis.max
                  }
                }
                return this.previousXAxis
              }
          }
        },
        animations: {
          enabled: false,
        },
        id: "chart2",
        type: "line",
        height: 330,
        toolbar: {
          show: true,
          offsetX: 5,
          offsetY: -10,
          tools: {
            download: false,
            selection: true,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: []
          },
          export: {
            csv: {
              filename: 'plot_chart_export',
              columnDelimiter: ';',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            }
          },
          autoSelected: 'zoom' 
        },
        locales: [{
          "name": "ca",
          "options": {
            "months": this.languageService.adapter.getMonthNames("long"),
            "shortMonths": this.languageService.adapter.getMonthNames("long"),
            "days": this.languageService.adapter.getDayOfWeekNames("long"),
            "shortDays": this.languageService.adapter.getDayOfWeekNames("short")
          }
        }],
        defaultLocale: "ca"
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
          format: 'dd MMM - HH:mm'
        }
    },
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false
        }
/*         labels: {
          format: 'MM yyyy'
        } */
      },
      yaxis: {
        show: true,
        labels: {
          show: true
        },
        
      },
      colors : colors,
      annotations: {
        yaxis: [{
          y: this.limit,
          borderColor: '#FF0000',
          strokeDashArray: 3,
        }]
      }
    };

    this.chartOptions2 = {
      series: seriesData,
      chart: {
        animations: {
          enabled: false,
        },
        id: "chart1",
        height: 230,
        type: "area",
        brush: {
          target: "chart2",
          enabled: true
        },
        selection: {
          enabled: true,
          xaxis: {
            min: dateFrom.getTime(),
            max: dateTo.getTime()
          }
        },
        locales: [{
          "name": "ca",
          "options": {
            "months": this.languageService.adapter.getMonthNames("long"),
            "shortMonths": this.languageService.adapter.getMonthNames("short"),
            "days": this.languageService.adapter.getDayOfWeekNames("long"),
            "shortDays": this.languageService.adapter.getDayOfWeekNames("short")
          }
        }],
        defaultLocale: "ca"
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0,
          opacityTo: 0
        }
      },
      xaxis: {
        type: "datetime"
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
          format: 'dd MMM - HH:mm'
        }
      },
      yaxis: {
        show: true,
        labels: {
          show: true
        },
        title: {
          text: this.graph_title,
          style: {
            fontSize:  '14px',
          },
          rotate: -90
        }
      },
      /*  

      yaxis: {
        tickAmount: 2
      }, 
      
      */
      colors : colors,
    };
  }
    this.plotFetching = false;
  }

  prepareCsvData(res: any){
    this.csvData = {}
    this.csvHeaders = []
    this.sortedDates = []
    for(let stationData of res){
      let stationDataKey = ""; 
      let web_p_c = stationData.web_pollutant_CAT.replace("<sub>","").replace("</sub>","");
      let web_p_e = stationData.web_pollutant_ES.replace("<sub>","").replace("</sub>","");;
      if(this.lang == 'ca-ES'){
        stationDataKey = web_p_c + " (" + stationData.unit + ")"
      } else if(this.lang == 'es-ES') {
        stationDataKey = web_p_e + " (" + stationData.unit + ")"
      } else {
        stationDataKey = web_p_c + " (" + stationData.unit + ")"
      }

      if(this.csvHeaders.indexOf(stationDataKey) == -1){
        this.csvHeaders.push(stationDataKey)
      }
      for(let measures of stationData.values) {
        let key = measures.beginPosition + "_" + measures.endPosition
        if(this.sortedDates.indexOf(key) == -1){
          this.sortedDates.push(key)
        }
        if(this.csvData[key] == undefined){
          this.csvData[key] = {}
        }
        this.csvData[key][stationDataKey] = measures.value
      }
    }
  }

  formatDate(currentDate: Date, start: boolean): string {
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
  
    let monthStr = month.toString();
    let dayStr = day.toString();
  
    if (month < 10) {
      monthStr = "0" + monthStr;
    }
  
    if (day < 10) {
      dayStr = "0" + dayStr;
    }
  
    let res = currentDate.getFullYear() + "-" + monthStr + "-" + dayStr;
    if (start) {
      res += "T00:00:00+01:00";
    } else {
      res += "T23:59:59+01:00";
    }
    return res;
  }

  generateTimeSeries(values: any){

    let timeSeries = []
    for(let elem of values){
      let curr = elem["value"]
      let value = null
      if(curr != null){
        value = Number(curr)
      }

      let date = new Date(elem["dateLocal"])/* .getTime() */
      //let date = new Date(elem["dateLocal"].split('+')[0]).getTime()
      //let date = new Date(elem["endPosition"].split('+')[0]).getTime()
      timeSeries.push([date, value])

    }
    return timeSeries
  }

  exportAsCsv(){

    this.translateService.get('EXPORT_EXCEL.INFO').subscribe((data:any)=> {
      this.info = data
     });

     this.translateService.get('EXPORT_EXCEL.QUESTION').subscribe((data:any)=> {
      this.question = data
     });
     this.translateService.get('EXPORT_EXCEL.COMMA').subscribe((data:any)=> {
      this.comma = data
     });

     this.translateService.get('EXPORT_EXCEL.DOT').subscribe((data:any)=> {
      this.dot = data
     });

    const dialogRef = this.dialog.open(DialogCsvComponent, {
      data: {
        title: this.info,
        question: this.question,
        option1: this.comma,
        option2: this.dot
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "option2") {
        this.excelService.exportAsCSV(this.csvData, this.csvHeaders, this.sortedDates, this.selectedStation.nameNoAccents + "_" + Helper.getCalendarFormattedDate(new Date()), ',')
      }
      else if (result === "option1") {
        this.excelService.exportAsCSV(this.csvData, this.csvHeaders, this.sortedDates, this.selectedStation.nameNoAccents + "_" + Helper.getCalendarFormattedDate(new Date()), ';')
      }
    })    
  }

  getLatestData(lang : string){
    this.latestDataFetching = true
    this.stationService.getLatestData(this.selectedStation.stationId, lang).subscribe((res) =>{
        res.map(element=>{
          if(element.index === '0' || element.index === 'NaN' || element.index === '-'){
            element.index = " ";
          }
          else{
            element.index = Math.trunc(parseInt(element.index)).toString();
          }
            element.date_str = new Date(element.date).toLocaleTimeString() + " " + new Date(element.date).toLocaleDateString();
        });
      this.latestData = res;
      this.latestDataFetching = false;

      },
    error => {
      this.latestData = undefined
      this.latestDataFetching = false;
    }
    );
  }

  hexToRgbA(hex: string, transparency: number){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + transparency + ')';
    }
    throw new Error('Bad Hex');
  }

  pollutantSelect(){
    if(this.oldValues != this.pollutants.value){
      this.oldValues = this.pollutants.value
      this.showLastDays(this.daysSelected)
    }
  }

  getInt(num: number){
    return Math.trunc(num)
  }

}
