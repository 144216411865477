import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { MapsComponent } from './pages/maps/maps.component';
import { DataAndStatisticsComponent } from './pages/data-and-statistics/data-and-statistics.component';
import { AboutAirComponent } from './pages/about-air/about-air.component';
import { AboutWebComponent } from './pages/about-web/about-web.component';
import { AccessibilityComponent } from './pages/accessibility/accessibility.component';
import { WebMapComponent } from './pages/web-map/web-map.component';
import { LegalWarnComponent } from './pages/legal-warn/legal-warn.component';
import { StationComponent } from './pages/station/station.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PollutionComponent } from './pages/pollution/pollution.component';
import { RegulationComponent } from './pages/regulation/regulation.component';
import { MeasuringEquimpentComponent } from './pages/measuring-equimpent/measuring-equimpent.component';
import { AirQualitiyLevelComponent } from './pages/air-qualitiy-level/air-qualitiy-level.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'station', component: StationComponent },
  { path: 'maps', component: MapsComponent },
  { path: 'data_and_statistics_home', component: DataAndStatisticsComponent },
  { path: 'about_air', component: AboutAirComponent , 
    children:[
    { path: '', component: PollutionComponent, outlet: "about_airRouter" },
    { path: 'pollution', component: PollutionComponent, outlet: "about_airRouter" },
    { path: 'regulations', component: RegulationComponent, outlet: "about_airRouter"},
    { path: 'measuring_equimpent', component: MeasuringEquimpentComponent, outlet: "about_airRouter" },
    { path: 'airQualitiyLevel', component: AirQualitiyLevelComponent, outlet: "about_airRouter"}]
  },
  { path: 'about_web', component: AboutWebComponent,
    children:[
      { path: '', component: ContactComponent, outlet: "aboutWebRouter" },
      { path: 'contact', component: ContactComponent, outlet: "aboutWebRouter" },
      { path: 'accessibility', component: AccessibilityComponent, outlet: "aboutWebRouter"},
      { path: 'web_map', component: WebMapComponent, outlet: "aboutWebRouter" },
      { path: 'legal_warn', component: LegalWarnComponent, outlet: "aboutWebRouter"}
    ] },
  { path: 'reports', component: ReportsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
