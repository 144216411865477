<div [innerHTML]="data"></div>
<!-- <div fxFill fxFlex class="genericContent" style="padding: 15px;">
    <div class="genericTitleSmall">{{'LEGAL_WARN.LEGAL_WARN' | translate}}</div>
    <hr>

    <p [innerHtml]="'LEGAL_WARN.DESC' | translate"></p>

    <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.PRIVACY_POLICY_TITLE' | translate"></div>

    <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.PERSONAL_DATA_PROTECTION_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.PERSONAL_DATA_PROTECTION' | translate"></p>

    <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.STATISTICAL_DATA_COLLECTION_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.STATISTICAL_DATA_COLLECTION' | translate"></p> -->

    <!--             <div class="genericTitle" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.GOOGLE_ANALYTICS_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.GOOGLE_ANALYTICS_DESC' | translate"></p> -->

    <!-- <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.COOKIES_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.COOKIES_DESC' | translate"></p>

    <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.TWITTER_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.TWITTER_DESC' | translate"></p>
    <p [innerHtml]="'LEGAL_WARN.TWITTER_DESC_2' | translate"></p>
    <table id="twitterTable">
        <tr>
            <td class="twitterTitle">Nom</td>
            <td class="twitterTitle">Descripció</td>
            <td class="twitterTitle">Emissor</td>
            <td class="twitterTitle">Domini web</td>
            <td class="twitterTitle">Més informació</td>
        </tr>
        <tr>
            <td>_gid</td>
            <td>Aquesta cookie és per la integració de Tenor i/o Google Analytics.</td>
            <td>Google</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://tenor.com/legal-terms">https://tenor.com/legal-terms</a> i 
                <a href="https://policies.google.com/technologies/cookies">https://policies.google.com/technologies/cookies</a>
            </td>
        </tr>
        <tr>
            <td>gt</td>
            <td>Aquesta cookie és per mantenir funcionalitat amb apps externes i per autorització.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>Qualsevol domini de Twitter</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>_ga</td>
            <td>Aquesta cookie és per la integració de Tenor i/o Google Analytics.</td>
            <td>Google</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://tenor.com/legal-terms">https://tenor.com/legal-terms</a> i 
                <a href="https://policies.google.com/technologies/cookies">https://policies.google.com/technologies/cookies</a>
            </td>
        </tr>
        <tr>
            <td>guest_id_ads</td>
            <td>Aquesta cookie és per mostrar publicitat quan no hi ha cap sessió iniciada.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>auth_token</td>
            <td>Aquesta cookie és per gestionar la sessió del compte i l'autenticació.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>ct0</td>
            <td>Aquesta cookie és per gestionar l'autenticació.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>att</td>
            <td>Aquesta cookie és per gestionar l'autenticació i el reinici de la contrasenya.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>Qualsevol domini de Twitter</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>guest_id</td>
            <td>Aquesta cookie és per gestionar l'autenticació.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>twid</td>
            <td>Aquesta cookie és per gestionar l'autenticació.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>guest_id_marketing</td>
            <td>Aquesta cookie és per gestionar el màrketing sense cap sessió iniciada.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>kdt</td>
            <td>Aquesta cookie és per autenticar un dispositiu conegut.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
        <tr>
            <td>personalization_id</td>
            <td>Aquesta cookie traça activitats de Twitter per oferir una experiència personalitzada.</td>
            <td>Twitter Inc. o bé Twitter Intl Co</td>
            <td>.twitter.com</td>
            <td>
                <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>
            </td>
        </tr>
    </table>

    <div class="genericTitleSmall" style="margin-top: 20px;" [innerHtml]="'LEGAL_WARN.USAGE_POLICY_TITLE' | translate"></div>
    <p [innerHtml]="'LEGAL_WARN.USAGE_POLICTY_DESC' | translate"></p>
</div> -->