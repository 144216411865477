import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutWebService {

  private aboutAirUrl = '/assets/data/';
  constructor(private _http : HttpClient) { }
  
  public getData(fileName: string, lang : string): Observable<any> {
    return  this._http.get(this.aboutAirUrl + lang + "/about-web/" + fileName + ".html", { responseType: 'text' });
  }
}
