<div fxFlex fxLayout="column" id="station-container"  *ngIf="selectedStation != undefined">

    <div class="bg-container" style="padding: 20px; padding-left: 30px;">
        <h3 style="font-weight: bold;" [innerHTML]="selectedStation.name"></h3>
        <h4 *ngIf="latestData.length != 0" class="genericTitleSmall">{{'STATION.LAST_MEASURED_DATA'| translate}}</h4>
        <div style="background-color: white;" class="table-responsive p-4 table-container">
            <table *ngIf="latestData.length != 0" class="table custom-table">
                <thead>
                    <tr>
                    <th scope="col">{{'STATION.PARAMETER'| translate}}</th>
                    <th scope="col">{{'STATION.LEVEL'| translate}}</th>
                    <th scope="col">{{'STATION.AIR_QUALITY'| translate}}</th>
                    <th scope="col">{{'STATION.VALUE'| translate}}</th>
                    <th scope="col">{{'STATION.PERIOD'| translate}}</th>
                    <th scope="col">{{'STATION.DATE'| translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of latestData">
                    <th scope="row" [innerHTML]="data.pollutant"></th>
                    <td>
                        <div style="  height: 20px; width: 20px; margin-left: 5px; color: white; text-align: center; font-size:14px"  [style.background-color]="data.color" fxLayout="row"  fxLayoutAlign="center center">
                        <span style="font-weight: 900; ; font-family:'Lato'">{{data.index}} </span>
                        </div>
                    </td>
                    <td [innerHTML]="data.band"></td>
                    <td [innerHTML]="data.concentration"></td>
                    <td [innerHTML]="data.period"></td>
                    <td [innerHTML]="data.date_str" [ngStyle]="{'color': checkDate(data.date, 3) ? 'red' : 'black'}"></td>
                    </tr>
                </tbody>
                </table>
                <div *ngIf="latestData.length == 0">
                    {{'STATION.NO_DATA'| translate}}
                </div>
        </div>
    </div>
   
    <div fxFlex fxLayout="column" class="bg-container p-5 mt-4 mb-4" *ngIf="selectedStation != undefined">
        <h3 style="font-weight: bold;">{{ 'WEBMAP.GRAPHICS2' | translate}}</h3>  
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"  [formGroup]="form">
            <mat-form-field>
                <mat-label style="color: black;">{{ 'WEBMAP.POLLUTANTS' | translate }}</mat-label>
                <mat-select [formControl]="this.pollutants" multiple (closed)="pollutantSelect()">
                    <mat-select-trigger>
                        {{this.pollutants.value != undefined ? this.pollutants.value[0][1] : ''}}
                        <span *ngIf="this.pollutants.value?.length > 1" class="example-additional-selection">
                    (+{{this.pollutants.value.length - 1}} {{ 'WEBMAP.MORE' | translate }} )
                    </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let pollutant of pollutantList" [value]="pollutant"><span [innerHTML]="pollutant[2]"></span></mat-option>
                </mat-select>
            </mat-form-field>
            <div fxFlex fxLayout.xs="column" fxLayout.sm="column" fxLayout="row"  fxLayoutAlign="start center" fxLayoutGap="20px" class="mx-4">
                <div class="showLast">
                    {{ 'WEBMAP.SHOW_LAST' | translate }}:
                </div>
                <div class="plotDayLink" (click)="showLastDays(7)">
                    7 {{ 'WEBMAP.DAYS' | translate }}
                </div>
                <div class="plotDayLink" (click)="showLastDays(14)">
                    14 {{ 'WEBMAP.DAYS' | translate }}
                </div>
                <div class="plotDayLink" (click)="showLastDays(30)">
                    30 {{ 'WEBMAP.DAYS' | translate }}
                </div>
                <div  fxLayout="row" fxLayoutAlign="start start">
                    <div id="csvButton">
                        <button mat-raised-button (click)="exportAsCsv()">{{'WEBMAP.CSV_EXPORT' | translate}}</button>
                    </div> 
                </div>
            </div> 
        </div>
        
        <div id="plotContainer" *ngIf="!plotFetching && plotData.length !== 0">
            <div id="wrapper">
                <div id="chart-line">
                    <apx-chart id="chart" #chart [series]="chartOptions1.series" [chart]="chartOptions1.chart" [xaxis]="chartOptions1.xaxis" [yaxis]="chartOptions1.yaxis" [dataLabels]="chartOptions1.dataLabels" [colors]="chartOptions1.colors" [annotations]="chartOptions1.series.length == 2 && limit != -1 ? chartOptions1.annotations : null" [fill]="chartOptions1.fill" [markers]="chartOptions1.markers"
                        [stroke]="chartOptions1.stroke" [tooltip]="chartOptions1.tooltip"></apx-chart>
                </div>
                <div id="chart-line2">
                    <apx-chart id="chart2" #chart2 [series]="chartOptions2.series" [chart]="chartOptions2.chart" [xaxis]="chartOptions2.xaxis" [colors]="chartOptions2.colors" [fill]="chartOptions2.fill" [yaxis]="chartOptions2.yaxis"></apx-chart>
                </div>
            </div>
        </div>
        <div id="plotContainer" *ngIf="!plotFetching && plotData.length === 0">
            {{'STATION.NO_DATA' | translate}}
        </div>
        <div *ngIf="plotFetching" class="progressBarContainer">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>    
    </div>

    <div class="bg-container" style="padding: 20px; padding-left: 30px; width: 100%;" *ngIf="selectedStation != undefined">
        <h3 class="genericTitleSmall">{{ 'WEBMAP.MORE_INFORMATION' | translate }}</h3>
        <table *ngIf="latestData.length != 0" id="table-more-info">
            <tbody>
                <tr>
                    <th>{{ 'WEBMAP.STATION_NAME' | translate }}</th>
                    <td [innerHTML]="selectedStation.name"></td>
                </tr>
                <tr>
                    <th>{{ 'ABOUT_AIR.MEASURING_EQUIMPENT' | translate }}</th>
                    <td [innerHTML]="selectedStation.equip"></td>
                </tr>
                <tr>
                    <th>{{ 'STATION.LOCATION_TYPE' | translate }}</th>
                    <td [innerHTML]="selectedStation.type"></td>
                </tr>
                <tr>
                    <th>{{ 'WEBMAP.LOCATION' | translate }}</th>
                    <td [innerHTML]="selectedStation.ubicacio"></td>
                </tr>
                <tr>
                    <th>{{ 'WEBMAP.COORDS' | translate }}</th>
                    <td>{{selectedStation.lat}}, {{selectedStation.long}}</td>
                </tr>
            </tbody>
            </table>
    </div>

</div>
<div *ngIf="selectedStation == undefined" class="progressBarContainer">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>    