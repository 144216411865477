import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WebService } from 'src/app/services/web.service';
import { TimeSerie } from 'src/app/models/timeSerie';
import { ExcelService } from 'src/app/services/excel.service';
import { DataStation } from 'src/app/models/dataStation';
import { Helper } from '../helper';
import { DialogCsvComponent } from 'src/app/components/dialog-csv/dialog-csv.component';
import { MatDialog } from '@angular/material/dialog';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexMarkers, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  colors: string[];
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-data-and-statistics',
  templateUrl: './data-and-statistics.component.html',
  styleUrls: ['./data-and-statistics.component.css']
})
export class DataAndStatisticsComponent implements OnInit {
  public chartOptions1: Partial<ChartOptions>;

  form: FormGroup;
  timeSeries = new FormControl()
  plotFetching = false
  stationName = ""
  daysSelected = 7
  timeSeriesList: TimeSerie[] = []
  filteredTimeSeriesList: TimeSerie[] = []
  oldValues: any = undefined
  firstDate: Date
  lastDate: Date
  months: string[] = [];
  days: string[] = [];
  shortDays: string[] = [];
  boto_visualitzar = false;

  csvData = {}
  csvHeaders = []
  sortedDates = []
  previousXAxis = undefined

  showChart = false;
  lang: string
  maxDate = new Date()
  minDate = new Date()
  info: string
  question: string
  comma: string
  dot: string

  constructor(private fb: FormBuilder,
    private webService: WebService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private translateService: TranslateService) {
    this.form = fb.group({
      timeSeries: [this.timeSeries, Validators.required]
    });
  }

  ngOnInit(): void {
    this.getTimeSeries();
    this.getFirstDate();
    this.lang = this.languageService.getCurrentLanguage();
    this.setLocale();
    this.languageService.newLanguageSelected.subscribe(res =>{
      this.getTimeSeries();
      this.getFirstDate();
      this.setLocale();
      this.lang = this.languageService.getCurrentLanguage();
      if(this.showChart)
        this.filter();  
    })
  }


  setLocale(){
    this.months = this.languageService.adapter.getMonthNames("long");
    this.days = this.languageService.adapter.getDayOfWeekNames("long")
    this.shortDays = this.languageService.adapter.getDayOfWeekNames("short");
  }
  getFirstDate(){
    this.webService.getFirstDatePlot().subscribe((res:Date)=>{
      if(res){
        let t = res["minTime"].toString().indexOf('+');
        //this.minDate = res
        console.log(res["minTime"].toString());
        let result = res["minTime"].toString().substring(0,t);
        this.minDate = result;
        console.log(this.minDate);
      }
    })
  }

  getTimeSeries(){
   
    this.webService.getTimeSeries().subscribe(res => {
      if(res){
        this.timeSeriesList = res
        this.timeSeriesList.forEach(timeserie => {
          
          timeserie.label_esta = timeserie.label.substring(0, timeserie.label.indexOf(',') + 2 )
          if(this.lang == 'ca-ES'){
            timeserie.label_esta += timeserie.web_pollutant_CAT
          } else if (this.lang == 'es-ES') {
            timeserie.label_esta += timeserie.web_pollutant_ES
          } else {
            timeserie.label_esta += timeserie.web_pollutant_CAT
          }
        });
        this.filterTimeSeries()
        
      }
      this.showLastDays(this.daysSelected)
    })
  }

  filterTimeSeries(){
    console.log('------------');
    if (this.firstDate != null && this.firstDate != undefined){
      this.firstDate.setHours(0,0,0);
    }
  
    if (this.lastDate != null && this.lastDate != undefined){
      this.lastDate.setHours(23,59,59);
    }
    this.showChart = false;
    
    this.timeSeries = new FormControl()
    this.filteredTimeSeriesList = [];
    for(let item of this.timeSeriesList){
      let fromTime = new Date(item.fromTime)
      let toTime = new Date(item.toTime)
      toTime.setHours(23)
      toTime.setMinutes(59)
      toTime.setSeconds(59)
      toTime.setMilliseconds(59)
      if(toTime >= this.firstDate && fromTime <= this.lastDate){
        this.filteredTimeSeriesList.push(item)
      }
    }
    
  }

  showLastDays(days: number){
    this.daysSelected = days
    let currentDate = new Date()
    currentDate.setUTCDate(currentDate.getDate() - days)
    let currentDateStrFrom = this.formatDate(currentDate, true)

    this.firstDate = new Date(currentDateStrFrom)
    this.lastDate = new Date()

    this.filterTimeSeries()

  }

  showThisDays(){
    let currentDate = new Date()
    let currentDateStrTo = this.formatDate(currentDate, false)
    currentDate.setUTCDate(currentDate.getDate() - 7)
    let currentDateStrFrom = this.formatDate(currentDate, true)

    this.firstDate = new Date(currentDateStrFrom)
    this.lastDate = new Date(currentDateStrTo)

    this.filterTimeSeries()

  }

  showLastWeek(){
    var monday = new Date();
    // set to Monday of this week
    monday.setDate(monday.getDate() - (monday.getDay() + 6) % 7);
    // set to previous Monday
    monday.setDate(monday.getDate() - 7);
    // create new date of day before
    var sunday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6);

    this.firstDate = new Date(monday)
    this.lastDate = new Date(sunday)
    this.filterTimeSeries()

    //this.getPlotData(this.formatDate(monday, true), this.formatDate(sunday, false))
  }

  showLastMonth(){
    var first = new Date();
    first.setDate(1);
    first.setMonth(first.getMonth()-1);

    var last = new Date(first.getFullYear(), first.getMonth() + 1, 0);

    this.firstDate = new Date(first)
    this.lastDate = new Date(last)
    this.filterTimeSeries()

    //this.getPlotData(this.formatDate(first, true), this.formatDate(last, false))
  }

  showThisYear(){
    let first = new Date(new Date().getFullYear(), 0, 1, 1);
    let currentDate = new Date()

    this.daysSelected = 365

    this.firstDate = new Date(first)
    this.lastDate = new Date(currentDate)
    this.filterTimeSeries()

    //this.getPlotData(this.formatDate(first, true), this.formatDate(currentDate, false))
  }

  showLastYear(){
    let first = new Date(new Date().getFullYear() - 1, 0, 1, 1);
    let last = new Date(new Date().getFullYear() - 1, 11, 31);

    this.daysSelected = 365

    this.firstDate = new Date(first)
    this.lastDate = new Date(last)
    this.filter();
  //  this.filterTimeSeries()

    //this.getPlotData(this.formatDate(first, true), this.formatDate(last, false))
  }

  filter(){
    
    this.boto_visualitzar = true;
    if(this.oldValues != this.timeSeries.value){
      this.oldValues = this.timeSeries.value
    }

    this.daysSelected = Math.floor((Date.UTC(this.lastDate.getFullYear(), this.lastDate.getMonth(), this.lastDate.getDate()) - Date.UTC(this.firstDate.getFullYear(), this.firstDate.getMonth(), this.firstDate.getDate()) ) /(1000 * 60 * 60 * 24));

    this.getPlotData(this.formatDate(this.firstDate, true), this.formatDate(this.lastDate, false))
  }

   getPlotData(dateFrom: string, dateTo: string){

    if(!this.plotFetching){
      this.plotFetching = true
      let timeSeriesIDs : DataStation[] = [];

      if(this.timeSeries.value != undefined) {
        for(let timeSerie of this.timeSeries.value){
          var timeSerieIDSpit = timeSerie.timeSerieID.split("-")
          if(timeSerieIDSpit[1] && timeSerieIDSpit[2]){
            let dataStation = new DataStation()
            dataStation.stationID = timeSerieIDSpit[1]
            dataStation.pollutantID = timeSerieIDSpit[2]

            timeSeriesIDs.push(dataStation)
          }
        }
      }

      // TODO obtenir station ID real
       this.webService.getAllPlotData(dateFrom, dateTo, timeSeriesIDs).subscribe(
        res => {
          console.log("207", res)
          // Obtenir dates mínimes i màximes en les quals hi ha dades per poder pintar correctament el gràfic
          
          let minDate: Date = null
          let maxDate: Date =  null

          if(res != null){
            this.showChart = true
            for(let pollutant of res){
              if (pollutant.values != null) {
                for(let elem of pollutant.values){
                  let date = new Date(elem["date"])            
                  if(minDate == null || (date < minDate)){
                    minDate = date
                  }
                  if(maxDate == null || (date > maxDate)){
                    maxDate = date
                  }
                }
              }
            }

            let colors = []
            this.webService.getGraphicColors().subscribe((colorResult)=>{
              if(colorResult){
                colors = colorResult
                this.initializeChart(res, minDate, maxDate, colors)
              }
              this.plotFetching = false
            })
          }
          else {
            this.plotFetching = false
            this.filterTimeSeries();
          }
          
        },
        error => {
          this.plotFetching = false
        }
      )
    }
  }

  initializeChart(res: any, dateFrom: Date, dateTo: Date, colors: string[]){
    let seriesData = []

    if(res.length > 0){
      this.stationName = res[0].station
    }

    for(let value of res){
      let name = this.lang == ("ca-ES") ? value.web_pollutant_CAT : value.web_pollutant_ES 
      seriesData.push(
        {
          name: value.station + " - " + name  + " (" + value.unit +  ")",
          //name: value.component,
          data: this.generateTimeSeries(value.values)
        }
      )
    }

    //console.log("series data:", seriesData)

    let currDate = new Date()
    let currDateHour = new Date()
    currDateHour.setHours(currDateHour.getHours()+10)

    this.chartOptions1 = {
      series: seriesData,
      chart: {
        events : {
          beforeZoom : (e, {xaxis}) => {
              let maindifference = currDateHour.valueOf() - currDate.valueOf();
              let zoomdifference =   xaxis.max - xaxis.min ;
              if( zoomdifference < maindifference )
              return this.previousXAxis
              else {
                this.previousXAxis = {
                  xaxis: {
                    min: xaxis.min,
                    max: xaxis.max
                  }
                }
                return this.previousXAxis
              }
          }
        },
        id: "chart2",
        type: "line",
        height: 330,
        toolbar: {
          show: true,
          offsetX: 5,
          offsetY: -10,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: []
          },
          export: {
            csv: {
              filename: 'plot_chart_export',
              columnDelimiter: ';',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            }
          },
          autoSelected: 'zoom' 
        },
        locales: [{
          "name": "ca",
          "options": {
            "months": this.months,
            "shortMonths": this.months,
            "days": this.days,
            "shortDays": this.shortDays
          }
        }],
        defaultLocale: "ca"
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
          format: 'dd MMM - HH:mm'
        }
    },
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false
        }
        /* min: new Date().getTime() */
      },
      
      colors : colors
      
    };
  }

  exportAsCSV(res: any){
    if(!this.plotFetching){
      this.plotFetching = true
      let timeSeriesIDs : DataStation[] = [];
  
      if(this.timeSeries.value != undefined) {
        for(let timeSerie of this.timeSeries.value){
          var timeSerieIDSpit = timeSerie.timeSerieID.split("-")
          if(timeSerieIDSpit[1] && timeSerieIDSpit[2]){
            let dataStation = new DataStation()
            dataStation.stationID = timeSerieIDSpit[1]
            dataStation.pollutantID = timeSerieIDSpit[2]
  
            timeSeriesIDs.push(dataStation)
          }
        }
      }
  
      this.webService.getAllPlotData(this.formatDate(this.firstDate, true), this.formatDate(this.lastDate, false), timeSeriesIDs).subscribe(
        res => {
          if(res){
            this.csvData = {}
            this.csvHeaders = []
            this.sortedDates = []
            for(let stationData of res){
              let stationDataKey;
              let web_p_c = stationData.web_pollutant_CAT.replace("<sub>","").replace("</sub>","");
              let web_p_e = stationData.web_pollutant_ES.replace("<sub>","").replace("</sub>","");;
              if(this.lang == 'ca-ES'){
                stationDataKey = stationData.stationNoAccents + " - " + web_p_c
              } else {
                stationDataKey = stationData.stationNoAccents + " - " + web_p_e
              }
              
              if(this.csvHeaders.indexOf(stationDataKey) == -1){
                this.csvHeaders.push(stationDataKey)
              }
              for(let measures of stationData.values) {
                let key = measures.beginPosition + "_" + measures.endPosition
                if(this.sortedDates.indexOf(key) == -1){
                  this.sortedDates.push(key)
                }
                if(this.csvData[key] == undefined){
                  this.csvData[key] = {}
                }
                this.csvData[key][stationDataKey] = measures.value
              }
            }
  
            /*
            console.log(this.csvData);
            console.log(this.csvHeaders);
            */

            this.translateService.get('EXPORT_EXCEL.INFO').subscribe((data:any)=> {
              this.info = data
             });

             this.translateService.get('EXPORT_EXCEL.QUESTION').subscribe((data:any)=> {
              this.question = data
             });
             this.translateService.get('EXPORT_EXCEL.COMMA').subscribe((data:any)=> {
              this.comma = data
             });

             this.translateService.get('EXPORT_EXCEL.DOT').subscribe((data:any)=> {
              this.dot = data
             });
  
            const dialogRef = this.dialog.open(DialogCsvComponent, {
              data: {
                title: this.info,
                question: this.question,
                option1: this.comma,
                option2: this.dot
              },
            });
        
            dialogRef.afterClosed().subscribe((result) => {
              if (result === "option1") {
                this.excelService.exportAsCSV(this.csvData, this.csvHeaders, this.sortedDates, "data" + "_" + Helper.getCalendarFormattedDate(new Date()), ';');
              }
              else if (result === "option2") {
                this.excelService.exportAsCSV(this.csvData, this.csvHeaders, this.sortedDates, "data" + "_" + Helper.getCalendarFormattedDate(new Date()), ',');
              }
            })             

            this.plotFetching = false
          }
        },
        error => {
          console.log(error);
          this.plotFetching = false
        }
      )
    }

  }

  generateTimeSeries(values: any){
    let timeSeries = []

    for(let elem of values){
      let curr = elem["value"]
      let value = null
      if(curr != null){
        value = Number(curr)
      }
      let date = new Date(elem["endPosition"].split('+')[0]).getTime()
      timeSeries.push([date, value])
    }

    return timeSeries
  }

  formatDate(currentDate: Date, start: boolean){
    let month = (currentDate.getMonth()+1)
    let day = currentDate.getDate()

    let monthStr = month.toString()
    let dayStr = day.toString()

    if(month < 10)
      monthStr = "0" + month
    
    if(day < 10)
      dayStr = "0" + day

    let res = currentDate.getFullYear() + "-" + monthStr + "-" + dayStr
    if(start){
      res += "T00:00:00+02:00"
    }
    else {
      res += "T23:59:59+02:00"
    }

    return res
  }

  select(){
    if(this.chartOptions1 != undefined && this.chartOptions1 != null)
      console.log(this.chartOptions1.series);
    
    console.log(this.timeSeries.value)
    if(this.chartOptions1 != undefined && this.chartOptions1 != null) {
      //if (this.chartOptions1.series.)
      
      this.showChart = false;
    }
    
    //if((!this.checkError() || !this.checkDates() || !this.checkYearDifference()) && this.boto_visualitzar) {
     // this.filter();
    //}
  }

  checkError():boolean{
    if(!this.firstDate || !this.lastDate || !this.timeSeries.value || (this.timeSeries.value && this.timeSeries.value.length == 0)){
      return true
    }
    return false
  }

  checkDates():boolean{

    if(this.firstDate && this.lastDate){
      if(this.firstDate.getTime() > this.lastDate.getTime()){
        return true
      } 
    }

    return false
  }

  checkYearDifference():boolean{
    if(this.firstDate && this.lastDate){
      if((Math.floor((Date.UTC(this.lastDate.getFullYear(), this.lastDate.getMonth(), this.lastDate.getDate()) - Date.UTC(this.firstDate.getFullYear(), this.firstDate.getMonth(), this.firstDate.getDate()) ) /(1000 * 60 * 60 * 24))) > 365){
        return true
      }
    }
    return false
  }

  selectAll(){
    this.timeSeries.setValue(this.filteredTimeSeriesList)
  }

  unselectAll(){
    this.timeSeries.setValue([])
    this.boto_visualitzar = false;
  }
}
