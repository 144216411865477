import {Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Station } from 'src/app/models/station';
import { TranslateService } from '@ngx-translate/core';
import { StationService } from 'src/app/services/station.service';
import { Router } from '@angular/router';
import { AgmMap } from '@agm/core';
import { StationData } from 'src/app/models/stationData';
import { LanguageService } from 'src/app/services/language.service';



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Input() selectedLocation:Station;
  @Input() mapTypeIds: (keyof typeof google.maps.MapTypeId)[];
  @Input() style: keyof typeof google.maps.MapTypeControlStyle;
  @ViewChild(AgmMap) map: AgmMap;
  latestData: [] = []
  current_hour : number;



  getOptions(): Partial<google.maps.MapOptions> {
    return {

      mapTypeControl: true,
      mapTypeControlOptions: {
        style: this.style && google.maps.MapTypeControlStyle[this.style],
        mapTypeIds: this.mapTypeIds && this.mapTypeIds.map(mapTypeId => google.maps.MapTypeId[mapTypeId]),
      },
    };
  }

  title: string;
  lat: number;
  lng : number;
  zoom : number;
  customLegend : string;
  showLegend :boolean;
  latitude: number;
  longitude: number;
  levels = {}
  mapStyles = [{featureType: "all", elementType: "labels", stylers: [{visibility: "off"}],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  }
];

  mapDataFetching = true
  mapData : StationData[] = []
  constructor(
    private stationService : StationService,
    private languageService : LanguageService,
    private router: Router) {}

  ngOnInit(): void {

    this.initializeVariables();
    this.getMapData()
    this.languageService.newLanguageSelected.subscribe(res =>{
      this.initializeVariables();
      this.getMapData();
      location.reload();
    })
  }

  initializeVariables(){
    this.title = 'Sabadell';
    this.lat = 41.5429;
    this.lng = 2.11050;
    this.zoom = 13.9
    this.customLegend = 'customLegend';
    this.showLegend = false;

    this.levels = {
      1: "AIRQUALITYLEVELS.EXCELLENT",
      2: "AIRQUALITYLEVELS.GOOD",
      3: "AIRQUALITYLEVELS.REGULAR",
      4: "AIRQUALITYLEVELS.DEFICIENT",
      5: "AIRQUALITYLEVELS.BAD",
      6: "AIRQUALITYLEVELS.TOOBAD",
      0: "AIRQUALITYLEVELS.NODATA",
    }
  }
  toggleLegend() {
    this.showLegend = !this.showLegend;
    var content = document.getElementById("legend-content");
    var content_ = document.getElementById("content_");
    if (this.showLegend) {
      content.style.maxHeight = "50px";
      //content.style.display = 'none';
      content_.hidden = true;
    }
    if (!this.showLegend) {
      content.style.maxHeight = "350px";
      //content.style.display = 'block';
      content_.hidden = false;
    }
  }

  getMapData(){

    this.stationService.getStations(this.languageService.getCurrentLanguage()).subscribe( res =>
      res.map (element =>{
        if(this.checkDate(element.date, 3)){
          element.index_NO2 = 0
        }
        else{
          let time = new Date(element.date.toLocaleString())
          element.time = time.getHours().toString();
          element.date = this.formatDateIntoLocalTime(element.date)
          element.index_NO2 = Math.trunc(element.index_NO2)
        }
          let station = this.setUrl(element);
          element.url = station.url;
          element.equip = station.equip;
          this.mapDataFetching = false
          if(station.equip === 'Sensor Electronic' ){
            element.url = this.createHexCustomMarker(element.index_NO2, element.index_NO2_Color);
          } else {
            element.url = this.createCustomMarker(element.index_NO2, element.index_NO2_Color);

          }
         
          this.mapData = res
        },
        error => {
          this.mapDataFetching = false
          this.mapData = undefined
        }
    ));
        let date = new Date();
        this.current_hour = date.getHours();
  }

  createCustomMarker(index: number, color : string){
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.id = "CircleMarker"
    const context = canvas.getContext('2d');
    canvas.width = 39;
    canvas.height = 39;
    // draw circle
    context.beginPath();
    context.arc(19.5, 19.5, 17, 0, 2 * Math.PI);
    context.strokeStyle = '#000000';
    context.fillStyle = color === null ? '#929397' : color;
    context.fill();
    context.lineWidth = 3;
    context.stroke();
   

    // draw number inside circle
    context.font = '24px Lato';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = '#fff';
    if(index > 0)
      context.fillText(index.toString(), 20, 21);
    return canvas.toDataURL();
  }

  createHexCustomMarker(index: number, color: string) {

    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.id = "HexMarker"
    canvas.width = 40;
    canvas.height = 40;
    var context = canvas.getContext('2d'),side = 1,
    size = 17,
    x = 19,
    y = 17;

    context.beginPath();
    context.moveTo(x + size * Math.cos(0), y + size * Math.sin(0));

    for (side; side < 7; side++) {
      context.lineTo(x + size * Math.cos(side * 2 * Math.PI / 6), y + size * Math.sin(side * 2 * Math.PI / 6));
    }

    context.strokeStyle = '#000000';
    context.fillStyle = color === null ? '#929397' : color;
    context.fill();
    context.lineWidth = 3;
    context.stroke();

    
    
    context.font = '24px Lato';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = '#fff';
    if(index > 0)
      context.fillText(index.toString(), 19, 19);
    return canvas.toDataURL();
  }

  goToStation(stationId:string){
    this.router.navigate(['./station'], { queryParams: { stationId: stationId} }); 
  }

  onMapReady(map: any){
    map.setOptions({
      fullscreenControl: false
    });
        if(window.innerWidth < 590){
      this.toggleLegend();
      this.zoom = 13;
    }
    }

  checkDate(lastDate: string, differenceLimit: number){
    let lastDataDate :Date  = new Date(lastDate);
    let currentDate : Date= new Date();
    const diffInHours = Math.abs(currentDate.getTime() - lastDataDate.getTime()) / 3600000;
    return diffInHours > differenceLimit
  }
  formatDateIntoLocalTime(dateStr: string){

    const date = new Date(dateStr);
    const options = {weekday:'long', year: 'numeric', month: 'long', day: 'numeric', hours : "numeric" } as const;
    const intDateFormat = new Intl.DateTimeFormat(this.languageService.getCurrentLanguage(), options);
    return this.capitalizeFirstLetter(intDateFormat.format(date).toString());
  }


  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

  setUrl(stationData : StationData){
    if( (stationData.equip === 'Sensor electr&ograve;nic' || stationData.equip === 'Sensor electr&oacute;nico') && stationData.index_NO2 !== 0){
      stationData.url = `./../../assets/img/hex${stationData.index_NO2}.png`;
      stationData.equip = "Sensor Electronic";
    }
    else if( (stationData.equip === 'Equipo de referencia' || stationData.equip === 'Equip de refer&egrave;ncia') && stationData.index_NO2 !== 0){
      stationData.url = `../../../assets/img/circle${stationData.index_NO2}.png`;
      stationData.equip = "Equip referencia";
    }
    else if( (stationData.equip === 'Sensor electr&ograve;nic' || stationData.equip === 'Sensor electr&oacute;nico') && stationData.index_NO2 === 0){
      stationData.url = "../../../assets/img/hex_sin_datos.png";
      stationData.equip = "Sensor Electronic";
    }
    else if( (stationData.equip === 'Equipo de referencia' || stationData.equip === 'Equip de refer&egrave;ncia') && stationData.index_NO2 === 0){
      stationData.url = "../../../assets/img/circle_sin_datos.png";
      stationData.equip = "Equip referencia";
    }
    return stationData;
  }

}


