import { Component, OnInit } from '@angular/core';
import { WebUtils } from 'src/app/models/WebUtils';
import { WebEpisode } from 'src/app/models/webEpisode';
import { LanguageService } from 'src/app/services/language.service';
import { StationService } from 'src/app/services/station.service';
import { WebService } from 'src/app/services/web.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  date;
  time
  vent: string = "-";
  temp: string = "-";
  humitat:string = "-";
  dataNotUpdated : boolean
  realtimeDateData: WebUtils;
  webEpisode : WebEpisode = new WebEpisode();
  constructor(private stationServive: StationService, private languageService : LanguageService, private webService: WebService) { }

  ngOnInit(): void {

   let lang = this.languageService.getCurrentLanguage();
    this.getData(lang);
    this.getAvisData(lang);
    this.getMeteoData();
    this.languageService.newLanguageSelected.subscribe(res =>{
      this.getData(res);
      this.getAvisData(res);
    })    
  }

  getAvisData(lang){
    this.webService.getAvis(lang).subscribe(res =>{
        this.webEpisode = res;
    })
  }
  getData(lang : string){

      this.stationServive.getWebUlits(lang).subscribe(res =>{  
        this.realtimeDateData = res;
        if (this.getHoursDifference(this.realtimeDateData.lastUpdate) > 3) {
          this.dataNotUpdated = true;
        } else {
          this.date = this.formatDateIntoLocalTime(this.realtimeDateData.lastUpdate.toString(), lang);
          this.time = new Date(this.realtimeDateData.lastUpdate.toLocaleString())
          this.time = this.time.getHours();
        }
      });

  }

  getMeteoData(){
    this.stationServive.getMeteoData().subscribe(data => {
      if(this.getHoursDifference(data.date) <= 3)
      {
        this.temp = Math.trunc(data.temp).toString();
        this.humitat = Math.trunc(data.rh).toString();
        this.vent = Math.trunc(data.ws).toString();
      }
    });
  }

  getHoursDifference(lastDate: string): number{
    let lastDataDate :Date  = new Date(lastDate);
    let currentDate : Date= new Date();
    const diffInHours = Math.abs(currentDate.getTime() - lastDataDate.getTime()) / 3600000;
    return diffInHours;
  }

  formatDateIntoLocalTime(dateStr: string, lang : string){

    const date = new Date(dateStr);
    const options = {weekday:'long', year: 'numeric', month: 'long', day: 'numeric', hours : "numeric" } as const;
    const intDateFormat = new Intl.DateTimeFormat(lang, options);
    return this.capitalizeFirstLetter(intDateFormat.format(date).toString());
  }


  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

}

