<div class="side-container p-4" style="background-color: #F7F6F6 !important;">
    <div class="genericTitleSmall"><span style="color: black;">{{'ABOUT_WEB.ABOUT_WEB' | translate}}</span> </div>
    <div fxLayout="column">
        <button [routerLink]="[{outlets: {'aboutWebRouter': ['contact']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_web/(aboutWebRouter:contact)') ||  selectedMenu('/about_web')}">
            <div style="background-color: #F7F6F6 !important;" class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'ABOUT_WEB.CONTACT' | translate}}</a>
            </div>
        </button>
        <hr>
        <button style="background-color: #F7F6F6 !important;" [routerLink]="[{outlets: {'aboutWebRouter': ['accessibility']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_web/(aboutWebRouter:accessibility)')}">
            <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'ACCESSIBILITY.ACCESSIBILITY' | translate}}</a>
            </div>
        </button>
        <hr>
        <button style="background-color: #F7F6F6 !important;" [routerLink]="[{outlets: {'aboutWebRouter': ['web_map']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_web/(aboutWebRouter:web_map)')}">
            <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'WEBMAP.WEBMAP' | translate}}</a>
            </div>
        </button>
        <hr>
        <button style="background-color: #F7F6F6 !important;" [routerLink]="[{outlets: {'aboutWebRouter': ['legal_warn']}}]" color="primary" [ngClass]="{'selected-menu': selectedMenu('/about_web/(aboutWebRouter:legal_warn)')}">
            <div class="p-1 genericContent" fxLayout="row" fxLayoutAlign="space-between center">
                <a>{{'LEGAL_WARN.LEGAL_WARN' | translate}}</a>
            </div>
        </button>
    </div>
</div>
