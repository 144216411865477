import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-air-side-bar',
  templateUrl: './air-side-bar.component.html',
  styleUrls: ['./air-side-bar.component.css']
})
export class AirSideBarComponent implements OnInit {

  constructor(private router : Router) { }
  @Output() selectedTab = new EventEmitter<string>();
  ngOnInit(): void {

  }

  selectedMenu(url:string):boolean{
    return this.router.url == url
  }

}
