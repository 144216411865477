import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localEs from '@angular/common/locales/es';
import localEn from '@angular/common/locales/en';
import localCa from '@angular/common/locales/ca';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

 
  newLanguageSelected = new Subject<string>();
  constructor(private translate: TranslateService, public adapter: DateAdapter<Date>) {
   registerLocaleData(localCa, 'ca-ES');
   registerLocaleData(localEs, 'es-ES');
    translate.setDefaultLang('ca-ES');
    translate.addLangs(['ca-ES', 'es-ES' ]);
  }

  getCurrentLanguage(): string{
    let language = localStorage.getItem('language');
    if(!language){
      return "ca-ES"
      }
    return language;
  }

  setCurrentLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.adapter.setLocale(language);
    this.newLanguageSelected.next(language);
  }
}