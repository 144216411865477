import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  cookiesPolicy:string = "";
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.get('COOKIESPOLICY.FOOTER_DESC', {}).subscribe((res: string) => {this.cookiesPolicy = res})
  }
  goToTop(){
    window.scrollTo(0, 0);
  }

}
