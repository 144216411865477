import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimeSerie } from '../models/timeSerie';
import { DataStation } from 'src/app/models/dataStation';
import { environment } from 'src/environments/environment';
import { PlotData } from '../models/plotData';
import { WebEpisode } from '../models/webEpisode';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class WebService {
    private _baseUrl: string;
  
    constructor(private http: HttpClient) {
      this._baseUrl = `${environment.apiUrl}`;
    }
  
    getMapData(lang: string): Observable<any[]>{
      return this.http.get<any[]>(this._baseUrl + '/WebMap', { 'headers': {
          "lang": lang
        }
      });
    }

    getLatestData(stationID: string, lang: string): Observable<any[]>{
      return this.http.post<any[]>(this._baseUrl + '/LatestDataItemController',  {}, { 'headers': {
          "stationID": stationID,
          "lang": lang
        }
      });
    }

    getPollutants(stationId: string): Observable<any[]>{
      return this.http.get<any[]>(this._baseUrl + '/Pollutant?stationID=' + stationId);
    }

    getTimeSeries(): Observable<TimeSerie[]>{
      return this.http.get<TimeSerie[]>(this._baseUrl + '/TimeSerie');
    }

    getStations(lang: string): Observable<any[]>{
      return this.http.post<any[]>(this._baseUrl + '/Stations', {}, { 'headers': {
          "lang": lang
        }
      });
    }

    // getStationByID(stationId:string, lang: string): Observable<any[]>{
    //   return this.http.post<any[]>(this._baseUrl + '/Station/stationByID', {}, { 'headers': {
    //       "stationID": stationId,
    //       "lang": lang
    //     }
    //   });
    // }

    getMapDataByID(stationId:string, lang: string): Observable<any[]>{
      return this.http.post<any[]>(this._baseUrl + '/web/mapDataByID', {}, { 'headers': {
        "stationID": stationId,
        "lang": lang
      }
    });
    }

    getPlotData(dateFrom: string, dateTo: string, stationID: string, pollutantIDs: string[]): Observable<PlotData[]> {
      const baseUrl = `${this._baseUrl}/PlotData`;
    
      // Construir la URL manualmente con los parámetros codificados correctamente
      const queryParams = [
        `dateFrom=${encodeURIComponent(dateFrom).replace('%2B', '+')}`,
        `dateTo=${encodeURIComponent(dateTo).replace('%2B', '+')}`,
        `stationID=${encodeURIComponent(stationID)}`
      ];
    
      pollutantIDs.forEach(element => {
        queryParams.push(`pollutantIDs=${encodeURIComponent(element)}`);
      });
    
      const fullUrl = `${baseUrl}?${queryParams.join('&')}`;
    
      return this.http.get<PlotData[]>(fullUrl);
    }
    

    getAllPlotData(dateFrom: string, dateTo: string, timeSeriesIDs: DataStation[]): Observable<any[]>{
      return this.http.post<any[]>(this._baseUrl + '/PlotData/GetAllPlotData', timeSeriesIDs, { 'headers': {
          "dateFrom": dateFrom,
          "dateTo": dateTo
        }
      });
    }

    getGlobalAQIData(lang: string, dateFrom: string): Observable<any[]>{
      return this.http.get<any[]>(this._baseUrl + '/web/globalAQIData', { 'headers': {
          "lang": lang,
          "dateFrom": dateFrom
        }
      });
    }

    getStationAQIData(lang: string, stationID: string): Observable<any[]>{
      return this.http.get<any[]>(this._baseUrl + '/AQIData/stationID' , { 'headers': {
          "lang": lang,
          "stationID": stationID
        }
      });
    }

    getUtils(lang: string): Observable<string>{
      return this.http.get<string>(this._baseUrl + '/web/utils', { 'headers': {
          "lang": lang
        }
      });
    }

    getCalendarData(lang: string, date: string): Observable<any[]>{
      return this.http.get<any[]>(this._baseUrl + '/web/calendarData', { 'headers': {
          "lang": lang,
          "dateFrom": date
        }
      });
    }
     
    getGraphicColors(): Observable<string[]>{
      return this.http.get<string[]>(this._baseUrl + '/WebGraphicColors', { 'headers': {}
      });
    }

    getFirstDatePlot(): Observable<Date>{
      return this.http.get<Date>(this._baseUrl + '/FirstPlotDate', { 'headers': {}
      });
    }

    getPollenWeekly(): Observable<any[]>{      
      return this.http.get<any[]>(this._baseUrl + '/web/pollenWeekly', { 'headers': {}
      });
    }

    getAnnualDataConfig(): Observable<any[]>{      
      return this.http.get<any[]>(this._baseUrl + '/web/annualDataConfig', { 'headers': {}
      });
    }

    getPollenPlotData(pollutant: string, year: string, yearToCompare: string): Observable<any[]>{
      return this.http.post<any[]>(this._baseUrl + '/web/pollenPlotData',  {}, { 'headers': {
        "pollutant": pollutant,
        "year": year,
        "yearToCompare": yearToCompare
      }
    });
    }


    getAvis(lang: string) :  Observable<WebEpisode>{
      return this.http.get<WebEpisode>(this._baseUrl + '/WebEpisodes/getAvis?lang=' +  lang).pipe(map(
        (res: any) => {
            return res;
        },
        (error: any) => {
            console.log("Get web avis error: " + JSON.stringify(error))
        }
      ));
    }
  }
  
