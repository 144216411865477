import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AppConfig {
  readonly apiUrl: string;
  readonly imageUrl: string;
  readonly documentUrl: string;
  readonly marketingUrl: string;
  readonly defaultCssColor: string;
}

export let APP_CONFIG : AppConfig;

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private http: HttpClient)
  {
  }

  public load()
  {
    return new Promise((resolve, reject) => {

      this.http.get('/assets/config/config.json').subscribe((envResponse :any) => {
        let t = new AppConfig();
        APP_CONFIG = Object.assign(t, envResponse);
        if(APP_CONFIG.defaultCssColor){
          document.documentElement.style.setProperty('--color-global', APP_CONFIG.defaultCssColor);
        }
        resolve(true);
      }, error => {
          reject(true);
          throw new Error("server error");
          ;
      });

    });
  }
}
