<div fxLayout="column" style="margin-top: 10px;" class="sabadell-Container">
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="padding: 2vw;">
        <div fxFill fxFlex class="genericContent">
            <div class="genericTitleSmall">{{'DATA.DATA_VIEW_DOWNLOAD_TITLE' | translate}}</div>
            <hr>
            <p class="genericTextSabadell">{{'DATA.DATA_DESC' | translate}}</p>
            <ul class="genericTextSabadell">
                <li>{{'DATA.DATA_DESC_LIST1' | translate}}</li>
                <li>{{'DATA.DATA_DESC_LIST2' | translate}}</li>
            </ul>
            <div class="mt_16 pt_16" [formGroup]="form" >
                <div *ngIf="!this.plotFetching">
                    <div id="plotFilters">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <div class="plotDayLink" (click)="showLastDays(7)">
                                {{ 'DATA.LAST_7_DAYS' | translate }}
                            </div>
                            <div class="plotDayLink" (click)="showLastDays(14)">
                                {{ 'DATA.LAST_14_DAYS' | translate }}
                            </div>
                            <div class="plotDayLink" (click)="showLastDays(30)">
                                {{ 'DATA.LAST_30_DAYS' | translate }}
                            </div>
                            <div class="plotDayLink" (click)="showThisYear()">
                                {{ 'DATA.THIS_YEAR' | translate }}
                            </div>
                            <div class="plotDayLink" (click)="showLastYear()">
                                {{ 'DATA.LAST_YEAR' | translate }}
                            </div>
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
                            <mat-form-field appearance="fill" class="example-form-field">
                                <mat-label>{{'DATA.SELECT_START_DATE' | translate}}</mat-label>
                                <input matInput [matDatepicker]="datepicker" [min]="minDate" [max]="maxDate" (dateInput)="filterTimeSeries()" (dateChange)="filterTimeSeries()" [(ngModel)]="this.firstDate" [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #datepicker >
                                    <!--                                     <mat-datepicker-actions>
                                        <button mat-raised-button color="primary" matDatepickerApply>Aplicar</button>
                                    </mat-datepicker-actions> -->
                                </mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="example-form-field">
                                <mat-label>{{'DATA.SELECT_END_DATE' | translate}}</mat-label>
                                <input matInput [matDatepicker]="datepicker2" [min]="minDate" [max]="maxDate" (dateChange)="filterTimeSeries()" [(ngModel)]="this.lastDate" [ngModelOptions]="{standalone: true}">
                                <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
                                <mat-datepicker #datepicker2>
                                    <!--  <mat-datepicker-actions>
                                        <button mat-raised-button color="primary" matDatepickerApply>Aplicar</button>
                                    </mat-datepicker-actions> -->
                                </mat-datepicker>
                            </mat-form-field>
                        </div>

                        <mat-error style="margin-bottom: 5px;" *ngIf="checkDates()">
                            {{'WEBMAP.DATE_ERROR' | translate}}
                        </mat-error>

                        <!--                         <div fxLayout="row">
                            <p>{{this.firstDate | date:'dd/MM/yyyy'}} | {{this.lastDate | date:'dd/MM/yyyy'}}</p>
                        </div> -->

                        <div fxLayout="row" fxLayoutGap="10px">
                            <div *ngIf="filteredTimeSeriesList.length > 0" fxLayout="column" fxFlex="40">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'WEBMAP.TIMESERIES' | translate}}</mat-label>
                                    <mat-select (selectionChange)="select()" [formControl]="this.timeSeries" multiple>
                                        <mat-select-trigger>
                                           <span [innerHTML]="this.timeSeries.value != undefined && this.timeSeries.value[0] ? this.timeSeries.value[0].label_esta : ''" ></span>
                                            <span *ngIf="this.timeSeries.value?.length > 1" class="example-additional-selection">
                                    (+{{this.timeSeries.value.length - 1}} {{ 'WEBMAP.MORE' | translate }} )
                                    </span>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let timeSerie of filteredTimeSeriesList" [value]="timeSerie">
                                            <span [innerHTML]="timeSerie.label_esta"> </span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div *ngIf="filteredTimeSeriesList.length <= 0" fxLayout="column" fxFlex="40">
                                <p>{{ 'WEBMAP.NOT_TIMESERIES' | translate }}</p>
                            </div>

                            <div *ngIf="filteredTimeSeriesList.length > 0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                                <div class="plotDayLink" (click)="selectAll()">{{'DATA.SELECT_ALL' | translate}}</div>
                                <div class="plotDayLink" (click)="unselectAll()">{{'DATA.DELETE_SELECTED' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div style="clear: both;"></div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <button mat-raised-button color="primary" [disabled]="checkError() || checkDates() || checkYearDifference()" (click)="filter()">{{"WEBMAP.FILTER" | translate}}</button>
                        <button mat-raised-button (click)="exportAsCSV()" [disabled]="checkError() || checkDates()">{{'WEBMAP.CSV_EXPORT' | translate}}</button>
                    </div>

                    <mat-error style="margin-top: 10px;" *ngIf="checkError() && this.timeSeries.value != undefined && this.timeSeries.value.length == 0">
                        {{ 'WEBMAP.SELECT_TIMESERIES' | translate }}
                    </mat-error>

                    <div id="plotContainer">
                        <div id="wrapper">
                            <div id="chart-line">
                                <apx-chart *ngIf="showChart && !checkError() && !checkDates() && !checkYearDifference()" id="chart" #chart [series]="chartOptions1.series" [chart]="chartOptions1.chart" [xaxis]="chartOptions1.xaxis" [dataLabels]="chartOptions1.dataLabels" [colors]="chartOptions1.colors"
                                    [fill]="chartOptions1.fill" [markers]="chartOptions1.markers" [stroke]="chartOptions1.stroke" [tooltip]="chartOptions1.tooltip"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.plotFetching" id="progressBarContainer">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </div>
    </div>
    <br>
    <div style="margin-bottom: 150px;"></div>
</div>
<br>